import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { LoyaltyIntroductionPagesEffects } from './loyalty-introduction-pages.effects';
import { loyaltyIntroductionPagesReducer } from './loyalty-introduction-pages.reducer';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature('loyaltyIntroductionPages', loyaltyIntroductionPagesReducer),
        EffectsModule.forFeature([
            LoyaltyIntroductionPagesEffects
        ]),
    ],
    exports: [],
    providers: [],
})
export class LoyaltyIntroductionPagesStateModule { }
