import { NgModule, Inject } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes, Router, RouteConfigLoadEnd, NavigationEnd } from '@angular/router';

import { AppModeOrderingGuard } from '@shared/core/guards/app-mode-ordering.shared.guard';
import { UniqueCodeGuard } from '@shared/core/guards/unique-code.shared.guard';
import { DynamicPathsGuard } from '@shared/core/guards/dynamic-paths.shared.guard';
import { SelectivePreloadingStrategyService } from '@shared/core/resolvers/selective-preloading-strategy.shared.service';
import * as Tokens from '@shared/core/tokens';
import * as Services from '@shared/core/services';
import { CONFIG } from '@tolo/config';

const startRoutes = (CONFIG.isLiveViewEnabled) ? [] : [{
    path: 'order-confirmation',
    loadChildren: () => import('./views/OrderConfirmationOLD/order-confirmation-OLD.module').then(m => m.OrderConfirmationOLDModule),
    canActivate: [
        AppModeOrderingGuard
    ],
}];

export const routes: Routes = [
    ...startRoutes,
    {
        path: 'payment-gateway',
        loadChildren: () => import('./views/PaymentGateway/payment-gateway.module').then(m => m.PaymentGatewayModule),
        canActivate: [
            AppModeOrderingGuard
        ],
    },
    {
        path: 'sign-up',
        loadChildren: () => import('./views/SignUp/sign-up.module').then(m => m.SignUpModule),
        canActivate: [
            UniqueCodeGuard,
        ],
    },
    {
        path: '',
        loadChildren: () => import('./views/views.module').then(m => m.ViewsModule),
    },
    {
        path: '**',
        loadChildren: () => import('./views/Error404/error404.module').then(m => m.Error404Module),
        canActivate: [
            DynamicPathsGuard,
        ],
    },
];

@NgModule({
    imports: [
        BrowserAnimationsModule,
        RouterModule.forRoot(routes,
            {
                // preloadingStrategy: QuicklinkStrategy, // Resolvers.SelectivePreloadingStrategyService,
                useHash: Boolean(history.pushState) === false,
                scrollPositionRestoration: 'enabled'
            })
    ],
    exports: [
        RouterModule
    ],
    providers: [
        SelectivePreloadingStrategyService
    ]
})
export class RootRoutesModule {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _router: Router,
        private _dynamicPathsGuard: DynamicPathsGuard,
        private _googleAnalytics: Services.GoogleAnalyticsService,
    ) {
        if (this._config?.venue?.name) {
            this._router.events.subscribe(async routerEvent => {
                if (routerEvent instanceof NavigationEnd) {
                    setTimeout(() => {
                        this._googleAnalytics.trackRouteNavigation(routerEvent.urlAfterRedirects);
                    }, 0);
                }

                if (routerEvent instanceof RouteConfigLoadEnd) {

                    // Trigger change detection so _loadedConfig is available in router
                    setTimeout(() => {
                        let configIsChanged = false;
                        this._router.config.forEach(root => {
                            if (root.path === '' && (root as any)._loadedConfig) {
                                const foundChild: any = (root as any)._loadedConfig;
                                if (!foundChild) return;

                                try {
                                    const foundMainChild = foundChild
                                        .routes?.find(obj => obj.path === '' && obj.children.some(inner => inner.path === 'VENUE_DYNAMIC_URL')) || null;

                                    if (foundMainChild) {
                                        const venueRoute = foundMainChild.children.find(inner => inner.path === 'VENUE_DYNAMIC_URL');
                                        if (venueRoute) {
                                            venueRoute.path = this._config.venue.name;
                                            venueRoute.data = { ...venueRoute.data, isDynamic: true };
                                            configIsChanged = true;
                                        }
                                    }
                                } catch (ex) {
                                    // console.warn('Unable to set locations dynamic routes.  Try refresh app', ex, foundChild);
                                }

                            }
                        });

                        this._dynamicPathsGuard.routeUpdate$.next(configIsChanged);
                    }, 1000);
                }
            });
        } else {
            this._dynamicPathsGuard.routeUpdate$.next(false);
        }
    }
}

