import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class FormsService {
    private _registry: Map<string, FormGroup> = new Map();

    public register(key: string, formGroup: FormGroup): void {
        this._registry.set(key, formGroup);
    }

    public unregister(key: string): void {
        this._registry.delete(key);
    }

    public getFormControl(key: string): FormGroup {
        return this._registry.get(key);
    }
}
