import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { MenuFlowDefaultActivationsEffects } from './menu-flow-default-activations.effects';
import { menuFlowDefaultActivationsReducer } from './menu-flow-default-activations.reducer';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature('menuFlowDefaultActivations', menuFlowDefaultActivationsReducer),
        EffectsModule.forFeature([
            MenuFlowDefaultActivationsEffects
        ]),
    ],
    exports: [],
    providers: [],
})
export class MenuFlowDefaultActivationsStateModule { }
