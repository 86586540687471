import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MaxOnScreenHeightService {
    /* For height calculations */
    public bottomOffset: number = 20;
    public sourceRegistry: Map<string, HTMLElement> = new Map();
    public targetRegistry: Map<string, HTMLElement> = new Map();
    public heightAdjustItemsRegistry: Map<string, Map<string, HTMLElement>> = new Map();
    public itemsRegistry: Map<string, Map<string, HTMLElement>> = new Map();
    public emittersRegistry: Map<string, BehaviorSubject<number>> = new Map();

    public getHeightListener$(key: string): BehaviorSubject<number> {
        this._setEmitter(key, true);

        return this.emittersRegistry.get(key);
    }

    public registerSource(key: string, elem: HTMLElement = null): void {
        this._setEmitter(key, !!elem);

        if (!elem) {
            this.sourceRegistry.delete(key);
        } else {
            this.sourceRegistry.set(key, elem);
        }

        this._emitHeightUpdate(key);
    }

    public registerTarget(key: string, elem: HTMLElement = null): void {
        this._setEmitter(key, !!elem);

        if (!elem) {
            this.targetRegistry.delete(key);
        } else {
            this.targetRegistry.set(key, elem);
        }

        this._emitHeightUpdate(key);
    }

    public registerHeightAdjustItem(key: string, subKey: string, elem: HTMLElement = null): void {
        this._configureSubMap('heightAdjustItemsRegistry', key, subKey, elem);
        this._emitHeightUpdate(key);
    }


    public registerItem(key: string, subKey: string, elem: HTMLElement = null): void {
        this._configureSubMap('itemsRegistry', key, subKey, elem);
        this._emitHeightUpdate(key);
    }

    public updateRequest(key: string): void {
        return this._emitHeightUpdate(key);
    }

    private _setEmitter(key: string, create: boolean = true): boolean {
        if (!create) {
            return this.emittersRegistry.delete(key);
        }
        const current = this.emittersRegistry.get(key);

        if (!current) {
            this.emittersRegistry.set(key, new BehaviorSubject(0));
            return true;
        }

        return true;
    }

    private _emitHeightUpdate(key: string): void {
        setTimeout(() => {
            const source: HTMLElement = this.sourceRegistry.get(key);
            const target: HTMLElement = this.sourceRegistry.get(key);
            const emitter: BehaviorSubject<number> = this.emittersRegistry.get(key);

            if (!source || !target || !emitter) return;

            const rects: ClientRect = source.getClientRects()[0];
            if (!rects) return;

            const sourceTopOffset: number = rects.top;

            let heightAdjustTotal: number = 0;
            const subHeightAdjustReg = this.heightAdjustItemsRegistry.get(key);
            if (subHeightAdjustReg) {
                for (let [key, value] of subHeightAdjustReg) {
                    heightAdjustTotal += value.scrollHeight;
                }
            }

            let innerItemsTotalHeight: number = 0;
            const subItemsRegistry = this.itemsRegistry.get(key);
            if (subItemsRegistry) {
                for (let [key, value] of subItemsRegistry) {
                    innerItemsTotalHeight += value.scrollHeight;
                }
            }


            const maxHeight: number = Math.abs(window.innerHeight - sourceTopOffset - heightAdjustTotal - this.bottomOffset);
            const newHeight: number = innerItemsTotalHeight < maxHeight ? innerItemsTotalHeight : maxHeight;

            emitter.next(newHeight);
        }, 20);
    }

    private _configureSubMap(mapName: string, key: string, subKey: string, elem: HTMLElement): void {
        let subReg: Map<string, HTMLElement> = this[mapName].get(key);

        if (elem) {
            if (!subReg) {
                this[mapName].set(key, new Map());
                subReg = this[mapName].get(key);
            }

            subReg.set(subKey, elem);
        } else {
            if (subReg) {
                subReg.delete(subKey);

                /* Do clean up when there are no items */
                let subItems: number = 0;
                for (let [key, value] of subReg) {
                    subItems += 1;
                }

                if (!subItems) {
                    this[mapName].delete(key);
                }
            }
        }
    }
}
