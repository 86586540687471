import { Injectable, Inject } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';
import * as StateModels from '../interface';

import * as Services from '@shared/core/services';
import * as Utils from '@shared/core/utils';
import * as Tokens from '@shared/core/tokens';

import { Observable, of, never } from 'rxjs';
import { catchError, switchMap, map, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class OnlineMenuEffects {
    @Effect() public onPickupTimeSetGetOnlineMenu$: Observable<Action> = this._actions$.pipe(
        ofType(actions.CurrentLocationPickupTimeSet),
        withLatestFrom(
            this._store.pipe(select(selectors.routeIsLocationDetailsPage(this._config))),
            this._store.pipe(select(selectors.getCurrentLocationNoWithMemberId)),
            this._store.pipe(select(selectors.getCurrentPickupTime)),
            this._store.pipe(select(selectors.getOnlineMenu)),
        ),
        switchMap(([action, isOnLocationDetailsPage, { memberId, locationNo }, pickupTime, onlineMenu]) => {
            const pickupTimeIsSet: boolean = !!pickupTime;
            const pickupTimeMatchesOnlineMenuRange: boolean =
                onlineMenu.data !== null && Utils.Dates.isHourInHoursRange(pickupTime?.Hour, onlineMenu.data.StartTime, onlineMenu.data.EndTime);
            const isSameDay: boolean = pickupTime?.DateLocalISO.split('T')[0] === onlineMenu.pickupDate?.split('T')[0];
            const locationHasChanged: boolean = locationNo !== onlineMenu.locationNo;
            const menuHasDownloaded: boolean = onlineMenu.data !== null;

            const shouldRequestOnlineMenu: boolean =
                isOnLocationDetailsPage && (locationHasChanged || !menuHasDownloaded || !pickupTimeMatchesOnlineMenuRange || !isSameDay || !pickupTimeIsSet);

            if (!shouldRequestOnlineMenu) return never();

            return of(actions.OnlineMenuPagesRequest(locationNo, memberId, Utils.Dates.getLocalISOFormatDate(pickupTime.Date, true)));
        }),
    );

    @Effect() public requestOnlineMenu$: Observable<Action> = this._actions$.pipe(
        ofType(actions.OnlineMenuPagesRequest),
        switchMap(({ locationNo, memberId, pickupDate }) =>
            this._onlineMenuService
                .getMenuPages({
                    locationNo,
                    memberId,
                    menuDate: pickupDate,
                })
                .pipe(
                    map((response) => {
                        /* Sort first by DisplayIndex */
                        response.Pages = response.Pages.sort((a, b) => {
                            switch (true) {
                                case a.DisplayIndex < b.DisplayIndex:
                                    return -1;
                                case a.DisplayIndex > b.DisplayIndex:
                                    return 1;
                                default:
                                    return 0;
                            }
                        });

                        return actions.OnlineMenuPagesSuccessRequest(locationNo, memberId, pickupDate, response);
                    }),
                    catchError((ex) => of(actions.OnlineMenuPageErrorRequest(locationNo, memberId, pickupDate, ex))),
                ),
        ),
    );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _actions$: Actions,
        private _onlineMenuService: Services.OnlineMenuService,
        private _store: Store<StateModels.IStateShared>,
    ) {}
}
