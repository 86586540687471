export class Objects {
    public static extractSubValue<T>(obj: Record<string, any>, value: string): T {
        if (!obj) return undefined;

        const props = value.split('.');

        const v = props.reduce((substate, property) => {
            if (substate === null) {
                if (!obj.hasOwnProperty(property)) return undefined;

                return obj[property];
            }

            if (!substate.hasOwnProperty(property)) return undefined;

            return substate[property];
        }, null);


        return v;
    }

    public static sumMapValues<T>(map: Map<T, number>): number {
        let total: number = 0;

        for (let [key, value] of map) {
            total += value;
        }

        return total;
    }

    public static getPropertyOrDefault(obj: Record<string, any>, propName: string, defaultValue: any = ''): any {
        return obj.hasOwnProperty(propName) ? obj[propName] : defaultValue;
    }

    public static isObject(obj: any): boolean {
        // return (typeof value === 'object' || typeof value === 'function') && value !== null;
        return !Objects.isPrimitive(obj);
    }

    public static getType(obj: any): string {
        return Object.prototype.toString.call(obj).replace(/(\[object\s|\])/gim, '')
            .toLowerCase();
    }

    public static uberDeepFreez(object: Record<string, any>): void {
        Object.freeze(object);

        Object.keys(object).forEach(key => {
            const type: string = Objects.getType(object[key]);
            if (type !== 'array' && type !== 'object') {
                return;
            }

            return Objects.uberDeepFreez(object[key]);
        });
    }

    public static isPrimitive(obj: any): boolean {
        switch (Objects.getType(obj)) {
            case 'number':
            case 'null':
            case 'string':
            case 'undefined':
            case 'symbol':
            case 'boolean':
                return true;
            default:
                return false;
        }
    }
}
