import { Injectable, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';
import * as State from '@shared/state/interface';

import { WizzardService } from './wizzard.shared.service';

import { Subscription, Subject, timer, Observable } from 'rxjs';
import { audit, filter, distinctUntilChanged, map, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ModalsService {
    private _modalContainer$: Subject<ElementRef> = new Subject(); /* Used for effects and blocking scroll */
    private _bgDropClick$: Subject<MouseEvent> = new Subject();
    private _wizzardScrollListener$: Subscription;

    public onWizzardScroll$: Subject<Event> = new Subject(); /* Use this in components to connect to wizzard scroll event */

    constructor(
        public store: Store<State.IModalState>,
        public router: Router,
        public wizzardService: WizzardService,
    ) {
        this._wizzardScrollListener$ = this.wizzardService.contentScrollListener$
            .pipe(
                filter(() => window.innerWidth < 610),
                audit(() => timer(500)),
            )
            .subscribe(event => this.onWizzardScroll$.next(event));
    }

    public set modalContainer(modalContainer: ElementRef) {
        this._modalContainer$.next(modalContainer);
    }

    public get modalsList$(): Observable<State.IModal[]> {
        return this.store.pipe(
            select(selectors.getAllModals),
            distinctUntilChanged()
        );
    }

    public get isAnyModalVisible$(): Observable<boolean> {
        return this.modalsList$
            .pipe(
                map(modals => modals.length > 0),
            );
    }

    public get isClosingAllModals$(): Observable<boolean> {
        return this.store.pipe(
            select(selectors.isLastModalHiding),
            distinctUntilChanged(),
        );
    }

    public get modalContainer$(): Observable<ElementRef> {
        return this._modalContainer$.asObservable();
    }

    public setLoading(modalId: number, isLoading: boolean = false): void {
        this.store.dispatch(actions.ModalSetLoading(modalId, isLoading));
    }

    public show(modal: State.IModal): Promise<boolean> {
        return new Promise(resolve => {
            const id: number = modal.id || new Date().getTime();

            this.store.dispatch(actions.ModalOpen({
                animate: State.MODAL_ANIMATION.IN,
                ...modal,
                id
            }));

            setTimeout(() => {
                resolve(true);
            }, 0);
        });
    }

    public swap(modalId: number, details: State.IModalSwap = {}): void {
        this.store.dispatch(actions.ModalSwap(modalId, {
            animate: null,
            ...details,
        }));
    }

    public close(id: number): void {
        this.store.dispatch(actions.ModalRequestClose(id));
    }

    public closeAllWithAnimation(): void {
        this.modalsList$
            .pipe(
                take(1)
            ).subscribe(modals => {
                modals.reverse().forEach(obj => this.close(obj.id));
            });
    }

    public closeAll(typesExcludedFromClosing: State.MODAL_TYPE[] = [], animation: State.MODAL_ANIMATION = State.MODAL_ANIMATION.OUT): void {
        this.store.dispatch(actions.ModalCloseAll(typesExcludedFromClosing, animation));
    }

    /* This will help detect background clicks */
    public emitBgClickEvent($event: MouseEvent): void {
        this._bgDropClick$.next($event);
    }

    /* You can subscribe to background events here */
    public backgroundEvents(): Observable<MouseEvent> {
        return this._bgDropClick$.asObservable();
    }

    public removeURLQueryParams(): Promise<boolean> {
        /* Obsolete - used only in aramark - moved to routeService */
        return this.router.navigate([], {
            queryParams: {
                modal: undefined,
                itemId: undefined,
            },
            queryParamsHandling: 'merge',
        });
    }

    public closeModal(modalId: number, unmountWizzard: boolean = true, removeQueryParams: boolean = false): void {
        this.close(modalId);

        if (unmountWizzard) {
            this.store.dispatch(actions.WizzardUnmountAll());
        }

        if (removeQueryParams) {
            this.removeURLQueryParams();
        }
    }
}
