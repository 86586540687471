import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';

import * as State from '@shared/state';
import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { Observable, combineLatest } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';
import { IOnlineOrderDetailedBusinessModel } from '@shared/state';

@Injectable({
    providedIn: 'root'
})
export class HistoryOrdersController {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _store: Store<State.IStateShared>,
    ) { }

    private _orderWithStateMapper(withState: boolean, ...filterByLocationNos: number[]):
    (orders: State.IOnlineOrderStateItemObj[]) => State.IOnlineOrderStateItemObj[] | IOnlineOrderDetailedBusinessModel[] {
        return (orders: State.IOnlineOrderStateItemObj[]) => {
            if (withState) {
                return filterByLocationNos.length === 0 ? orders : orders.filter(order => order.data && filterByLocationNos.includes(order.data.PickupLocation));
            }

            const reducedOrders: IOnlineOrderDetailedBusinessModel[] = orders.reduce((acc, order) => {
                if (!order.data) return acc;

                return [
                    ...acc,
                    order.data
                ];
            }, []);

            return filterByLocationNos.length === 0 ? reducedOrders : reducedOrders.filter(order => filterByLocationNos.includes(order.PickupLocation));
        };
    }

    public activeOrders$(withState: boolean = true, ...filterByLocationNos: number[]): Observable<State.IOnlineOrderStateItemObj[] | IOnlineOrderDetailedBusinessModel[]> {
        return this._store
            .pipe(
                select(selectors.getHistoryActiveOrders),
                map(this._orderWithStateMapper(withState, ...filterByLocationNos)),
            );
    }

    public historyOrders$(withState: boolean = true, ...filterByLocationNos: number[]): Observable<State.IOnlineOrderStateItemObj[] | IOnlineOrderDetailedBusinessModel[]> {
        return this._store
            .pipe(
                select(selectors.getHistoryOrders),
                map(this._orderWithStateMapper(withState, ...filterByLocationNos)),
            );
    }

    public mappedOrderItems$(orderId: number): Observable<OLO.Ordering.IOnlineOrderMappedProducts> {
        return this._store
            .pipe(
                select(selectors.getHistoryOrder(orderId)),
                filter(order => !!order && order.data !== null),
                map(order => Utils.OnlineOrders.mapOnlineOrderProducts(order.data, true))
            );
    }

    public status$(): Observable<{
        isDownloading: boolean;
        hasSucceeded: boolean;
        hasFailed: boolean;
    }> {
        return this._store
            .pipe(
                select(selectors.getHistoryOrdersStatus)
            );
    }

    public titleForActiveOrder$(orderId: number): Observable<string> {
        return this._store
            .pipe(
                select(selectors.getHistoryOrder(orderId)),
                map(order => {
                    if (!order || !order.data) return '';

                    return `Ready for pick up at ${new Utils.ShortHours(order.data.PickUpDate).getTransformedHour()}`;
                })
            );
    }

    public isOrderActive$(orderId: number): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.historyOrderIsActive(orderId)),
                filter(isActive => isActive !== null),
            );
    }

    public isOrderFinalized$(orderId: number): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.historyOrderIsFinalized(orderId))
            );
    }

    public isLoading$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isLoadingAnyHistoryOrder)
            );
    }

    public isLoadingFirstTime$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isRequestingHistoryOrderFirstTime),
            );
    }

    public hasOrders$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.hasAnyHistoryOrder)
            );
    }

    public hasHistoryOrders$(): Observable<boolean> {
        return combineLatest([
            this.isLoading$(),
            this.historyOrders$()
        ]).pipe(
            map(([loading, orders]) => {
                if (loading) return null;

                return orders.length > 0;
            })
        );
    }

    public hasActiveOrders$(): Observable<boolean> {
        return combineLatest([
            this.isLoading$(),
            this.activeOrders$()
        ]).pipe(
            map(([loading, orders]) => {
                if (loading) return null;

                return orders.length > 0;
            })
        );
    }

    public noHistoryOrders$(): Observable<boolean> {
        return combineLatest([
            this.isLoading$(),
            this.hasOrders$()
        ]).pipe(
            map(([isLoading, hasOrders]) => isLoading === false && !hasOrders)
        );
    }

    public getHistoryOrder$(orderId: number): Observable<State.IOnlineOrderStateItemObj> {
        return this._store
            .pipe(
                select(selectors.getHistoryOrder(orderId)),
            );
    }

    public getHistoryOrderDetails$(orderId: number): Observable<string> {
        return this.getHistoryOrder$(orderId)
            .pipe(
                map(order => (order?.data?.OrderTypeDetails?.reduce((acc, orderType) => [
                    ...acc,
                    orderType.ValueProvided
                ], []) || []).join(', '))
            );
    }

    public isStatusPlaced$(orderId: number): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isHistoryOrderPlaced(orderId)),
            );
    }

    public isStatusPreparing$(orderId: number): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isHistoryOrderPreparing(orderId)),
            );
    }

    public isStatusActive$(orderId: number): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isHistoryOrderActive(orderId)),
            );
    }

    public isStatusReady$(orderId: number): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isHistoryOrderReady(orderId)),
            );
    }

    public getHistoryOrderTableNo$(orderId: number): Observable<string> {
        return this.getHistoryOrder$(orderId)
            .pipe(
                filter(order => !!order?.data === true),
                map(order => {
                    const orderType = order.data.OrderTypeDetails.find(obj => obj.ValueProvided !== null);

                    return orderType?.ValueProvided || null;
                })
            );
    }

    public getHistoryOrderMemberId$(orderId: number) {
        return this.getHistoryOrder$(orderId)
            .pipe(
                map(order => order && order.data ? order.data.MemberId : null)
            );
    }

    public getHistoryOrderSummary$(orderId: number): Observable<OLO.Ordering.IOrderSummary> {
        return this.getHistoryOrder$(orderId)
            .pipe(
                map(order => new Utils.DetailedOrderTotalsReceiptCalculator(order?.data))
            );
    }

    public getHistoryOrderTitle$(orderId: number, includeLocationName: boolean = false): Observable<string> {
        return this.getHistoryOrder$(orderId)
            .pipe(
                filter(order => order !== undefined && order !== null && order.data !== null),
                switchMap(order => this._store
                    .pipe(
                        select(selectors.getLocationDetails(order.data.PickupLocation)),
                        filter(location => location !== null),
                        map(location => {
                            const isActiveOrder = Utils.OnlineOrders.isOrderActive(order.data.Status);
                            const locationName: string = includeLocationName ? `${location.LocationFriendlyName} - ` : '';

                            const converted: Date = Utils.Dates.createDate(order.data.PickUpDate);
                            if (converted.toString() === 'Invalid Date') return order.data.PickUpDate;

                            const formatedDate = Utils.Pickups.createPickupLabelName(
                                this._config.futureOrders,
                                'orderConfirmation',
                                converted,
                                Utils.Dates.datesDiffInDays(new Date(), converted) === 0,
                                'ddd, Do of MMM',
                            );


                            if (isActiveOrder) {
                                return `${locationName}Pickup ${formatedDate}`;
                            }

                            return `${locationName}${Utils.Dates.descriptionDate(order.data.PickUpDate)}`;
                        })
                    ))
            );
    }

    public canShowHistoryOrders$(active: boolean = false, ...locationNos): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.canShowHistoryOrders(active, ...locationNos)),
            );
    }

    public getHistoryOrderCollectionGroupType$(orderId: number): Observable<OLO.Enums.COLLECTION_TYPE> {
        return this._store
            .pipe(
                select(selectors.getHistoryOrderTypeGroup(orderId, this._config))
            );
    }

    public isDineInOrder$(orderId: number): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isHistoryOrderDineIn(orderId, this._config))
            );
    }

    public isDineInOrderBuzzerType$(orderId: number): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isHistoryOrderDineInBuzzer(orderId, this._config))
            );
    }

    public getOrderTypeDescription$(orderId: number, locationNo: number): Observable<string> {
        return this._store
            .pipe(
                select(selectors.getOrderTypeDescriptionForOrder(orderId, locationNo))
            );
    }

    public getHistoryOrderVoucher$(orderId: number): Observable<State.IActivatedOrderVoucherDetails> {
        return this._store
            .pipe(
                select(selectors.getHistoryOrderVoucher(orderId)),
            );
    }

}
