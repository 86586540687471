import 'zone.js';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
registerLocaleData(localePl, 'pl-PL');
import localeAU from '@angular/common/locales/en-AU';
registerLocaleData(localeAU, 'en-AU');
import localeNZ from '@angular/common/locales/en-NZ';
registerLocaleData(localeNZ, 'en-NZ');
import localeES from '@angular/common/locales/es-US';
registerLocaleData(localeES, 'es-US');

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { buildEnvironment } from './environments/environment.build';
import { CONFIG } from './app/config';

console.log(buildEnvironment);
if (CONFIG.google?.analytics?.length > 0 && window.gtag) {
    CONFIG.google?.analytics.forEach(obj => {
        window.gtag('config', obj.id);
    });
}
if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
