import { createAction, props } from '@ngrx/store';

export const LoyaltyIntroductionPageImageRequest = createAction(
    '[Loyalty Introduction Page Image] Request image',
    props<{ pageId: number; }>(),
);

export const LoyaltyIntroductionPageImageSuccessRequest = createAction(
    '[Loyalty Introduction Page Image] Success request image',
    props<{ pageId: number, payload: string; }>(),
);

export const LoyaltyIntroductionPageImageErrorRequest = createAction(
    '[Loyalty Introduction Page Image] Error request image',
    props<{ pageId: number, ex?: any; }>(),
);
