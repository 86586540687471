export class Numbers {
    public static leadingZero(number: number): string {
        return number < 10 ? `0${number}` : `${number}`;
    }

    public static isFloat(number: number): boolean {
        return Number.isFinite(number) && !Number.isInteger(number);
    }

    public static kilometersToMiles(number: number): number {
        return number * 0.621371192;
    }

    public static calulateNearestLargerMultipleOf(value: number, multiplier: number = 50): number {
        return Math.ceil(value / multiplier) * multiplier;
    }
}
