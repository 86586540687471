import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, IOnlineMenuFilters } from '../interface';

const state = createFeatureSelector<IStateShared, IOnlineMenuFilters>('onlineMenuFilters');

export const getOnlineMenuFilters = createSelector(
    state,
    onlineMenuFilters => onlineMenuFilters
);

export const getOnlineMenuFiltersSearchInput = createSelector(
    state,
    onlineMenuFilters => onlineMenuFilters?.search || null
);
