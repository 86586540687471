import { Injectable } from '@angular/core';

import * as State from '@shared/state/interface';
import { RouteService } from './route.shared.service';

import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { filter, auditTime } from 'rxjs/operators';
import { IMenuFlowDetailsModel } from '@shared/state/interface';

@Injectable({
    providedIn: 'root',
})
export class WizzardService {
    private _stepThroughState$: BehaviorSubject<OLO.Components.IWizzardStepThroughNextStateDetails> = new BehaviorSubject(null);
    private _onContentScrollInit$: Subject<HTMLDivElement> = new Subject();
    private _onContentScroll$: Subject<Event> = new Subject();
    public _navClickListener$: BehaviorSubject<number> = new BehaviorSubject(null);

    constructor(
        public routeService: RouteService,
    ) {
    }

    public get navClickListener$(): Observable<number> {
        return this._navClickListener$.asObservable();
    }

    public set navClickListener(index: number) {
        this._navClickListener$.next(index);
    }

    /* When scroll initialize - move this to separate service */
    public onWizzardScrollInit($event: HTMLDivElement): void {
        this._onContentScrollInit$.next($event);
    }

    public get contentScrollInitListener(): Observable<HTMLDivElement> {
        return this._onContentScrollInit$;
    }

    /* When scrolling TODO - move this to separate service */
    public onWizzardScrollContent($event: Event): void {
        this._onContentScroll$.next($event);
    }

    public get contentScrollListener$(): Subject<Event> {
        return this._onContentScroll$;
    }

    public validateWizzardItems(menuFlow: IMenuFlowDetailsModel, wizzardMenuFlow: State.IWizzardMenuFlow): State.IWizzardError[] {
        //
        //  Validate current wizzardMenuFlow
        //  It is used in different contexts and ways so it's just a method - not effect
        //
        const errorId = (): number => new Date().getTime() + Math.floor(Math.random() * 10000);
        const errors: State.IWizzardError[] = [];

        wizzardMenuFlow.Pages.forEach(wizzardPage => {
            const { PageMinQuantity, PageMaxQuantity } = menuFlow.Pages.find(page => page.PageIdentifier === wizzardPage.PageIdentifier);
            const wizzardPageTotalQuantity = wizzardPage.Products.reduce((acc, product) => acc += product.Quantity, 0);

            if (PageMinQuantity) {
                if (wizzardPageTotalQuantity < PageMinQuantity) {
                    errors.push({
                        id: errorId(),
                        pageIdentifier: wizzardPage.PageIdentifier,
                        error: State.WIZZARD_ERROR.REQUIRED,
                    });
                }
            }

            if (PageMaxQuantity) {
                if (wizzardPageTotalQuantity > PageMaxQuantity) {
                    errors.push({
                        id: errorId(),
                        pageIdentifier: wizzardPage.PageIdentifier,
                        error: State.WIZZARD_ERROR.OVERFLOW,
                    });
                }
            }

        });

        return errors;
    }


    public registerStepThroughWizzardChange(nextState: OLO.Components.IWizzardStepThroughNextStateDetails): void {
        this._stepThroughState$.next(nextState);
    }


    /**
     * Shows next step through state detailed information
     * @returns {OLO.Components.IWizzardStepThroughRegDataObj} OLO.Components.IWizzardStepThroughRegDataObj
     */
    public get nextStepThroughState$(): Observable<OLO.Components.IWizzardStepThroughNextStateDetails> {
        return this._stepThroughState$
            .pipe(
                filter(obj => obj !== null),
                auditTime(10)
            );
    }

}
