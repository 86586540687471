import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';

import { StateEffects } from './state.effects';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        EffectsModule.forFeature([
            StateEffects
        ]),
    ],
    exports: [],
    providers: [],
})
export class AppStateStateModule { }
