export interface IMetaTags {
    title?: string;
    keywords?: string;
    description?: string;
    author?: string;
}

export class Meta {
    public static setTags(tags: IMetaTags = {}): boolean {
        try {
            for (let tag in tags) {
                if (tag === 'title') {
                    let titleTag = document.querySelector('title');
                    if (!titleTag) {
                        titleTag = document.createElement('title');
                        document.head.appendChild(titleTag);
                    }

                    titleTag.innerHTML = tags[tag];
                } else {
                    let metaTag = document.querySelector(`meta[name="${tag}"]`);
                    if (!metaTag) {
                        metaTag = document.createElement('meta');
                        metaTag.setAttribute('name', tag);
                        document.head.appendChild(metaTag);
                    }

                    metaTag.setAttribute('content', tags[tag]);
                }
            }

            return true;
        } catch (ex) {
            console.error(ex);

            return false;
        }
    }

    public static clearTags(tags: ('title' | 'keywords' | 'description' | 'author')[]): boolean {
        try {

            for (let val of tags) {
                let tag;

                if (val === 'title') {
                    tag = document.querySelector('title');
                } else {
                    tag = document.querySelector(`meta[name="${val}"]`);
                }

                if (tag) {
                    if (val === 'title') {
                        tag.innerHTML = '';

                        return;
                    }

                    tag.setAttribute('content', '');
                }
            }

            return true;

        } catch (ex) {
            console.error(ex);

            return false;
        }
    }

    public static fixTitle(value: string, separator: string = ' - '): void {

        const PATTERN = /\{[^\\}]*\}/i;
        const titleTag = document.querySelector('title');
        if (titleTag) {
            const foundReplacer = titleTag.innerText.match(PATTERN);
            if (foundReplacer) {
                titleTag.innerHTML = titleTag.innerHTML.replace(PATTERN, value);
            } else {
                titleTag.innerHTML = `${value}${separator}${titleTag.innerHTML}`;
            }
        }
    }

    public static unsetFixedTitle(value: string, separator: string = ' - '): void {

        if (!value) {
            return;
        }
        const titleTag = document.querySelector('title');

        if (titleTag) {
            titleTag.innerHTML = titleTag.innerHTML.replace(`${value}${separator}`, '');
        }
        // if (titleTag) {
        //     const foundReplacer = titleTag.innerText.match(PATTERN);
        //     if (foundReplacer) {
        //         titleTag.innerHTML = titleTag.innerHTML.replace(PATTERN, value);
        //     } else {
        //         titleTag.innerHTML = `${value}${separator}${titleTag.innerHTML}`;
        //     }
        // }
    }
}
