import { Component, ViewChild, ViewContainerRef, Inject } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { Event, NavigationStart, Router, RouterEvent } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { fadeInOut } from '@shared/core/animations';
import { CartService, LazyLoaderService, ModalsService, InitService } from '@shared/core/services';
import { ENV_TOKEN } from '@shared/core/tokens';
import { ReturningMemberService } from '@shared/core/services/returning-member.shared.service';
import * as Services from '@shared/core/services';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [trigger('routeAnimations', [transition('* <=> *', [useAnimation(fadeInOut)])])],
})
export class AppComponent {
    @ViewChild('dev', { read: ViewContainerRef, static: false }) dev: ViewContainerRef;
    private _quickSignInHasBeenDisplayed: boolean = false;

    constructor(
        @Inject(ENV_TOKEN) private _env: IEnvironment,
        private _initService: InitService,
        private router: Router,
        private _modalsService: ModalsService,
        private _cartService: CartService,
        private _lazyLoaderService: LazyLoaderService,
        private _returningMemberService: ReturningMemberService,
        private readonly _sessionService: Services.SessionService,
    ) {
        this.router.events.pipe(filter((event: Event): event is RouterEvent => event instanceof NavigationStart)).subscribe(() => {
            this._modalsService.closeAll();

            if (!this._quickSignInHasBeenDisplayed) {
                this._returningMemberService.openLoginModalForReturningMember();
                this._quickSignInHasBeenDisplayed = true;
            }

            this._sessionService.getSession()
                .pipe(take(1))
                .subscribe(res => {
                    if (res) {
                        this._quickSignInHasBeenDisplayed = true;
                    }
                });
        });
    }

    ngAfterViewInit(): void {
        this._initService.init().then(() => {
            if (!this._env.production) {
                this._lazyLoaderService.loadModuleWithComponent({
                    viewRefContainer: this.dev,
                    name: 'dev',
                });
            }
        });
    }
}
