import { Injectable, Inject } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import * as State from '@shared/state/interface';
import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';
import { QueryParamsService } from './query-params.shared.service';

import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';


@Injectable({
    providedIn: 'root',
})
export class RouteService {
    public LOCATION_DETAILS_REGEXP: RegExp = /\/locations\/\d+\/details$/gim;

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public store: Store<State.IStateShared>,
        public router: Router,
        public queryParamsService: QueryParamsService,
    ) {
    }

    public get locationRootUrl(): string {
        return this.config.venue && this.config.venue.id && this.config.venue.name ? this.config.venue.name : 'locations';
    }

    public isCheckoutPage$(): Observable<boolean> {
        return this.store
            .pipe(
                select(selectors.isCheckoutPage)
            );
    }

    public navigate(command: any, extras?: NavigationExtras): Promise<boolean> {
        return this.router.navigate(command, extras);
    }

    public navigateToLocationsSearchView(): Promise<boolean> {
        return this.router.navigate([this.locationRootUrl]);
    }

    public async navigateToLocation(locationNo: number): Promise<boolean> {
        if (!locationNo) return this.router.navigate(['/']);

        try {
            let targetUrl: string = `/locations/${locationNo}/details`;
            let canOrderFromLocation: boolean = await this.store
                .pipe(
                    select(selectors.canOrderFromLocation(locationNo, this.config)),
                    take(1)
                ).toPromise();
            if (this.config.venue && this.config.venue.id && this.config.venue.name) {
                const locationFriendlyName: string = await this.store
                    .pipe(
                        select(selectors.getLocationDetails(locationNo)),
                        take(1),
                        map(location => {
                            if (!location) {
                                console.warn(`Unable to find location ${locationNo}`);

                                return '/';
                            }

                            return location.LocationFriendlyName.replace(/[^a-zA-Z0-9]/gim, '');
                        })
                    ).toPromise();

                if (locationFriendlyName === null) {

                    canOrderFromLocation = false;
                } else {
                    targetUrl = locationFriendlyName === '/' ? '/' : `/${this.locationRootUrl}/${locationFriendlyName}`;
                }
            }

            return !canOrderFromLocation ? null : this.router.navigate([targetUrl]);

        } catch (ex) {
            console.error(`Can't navigate to provided location ${locationNo}`, ex);

            return null;
        }
    }

    public async navigateToCartsLocation(): Promise<boolean> {
        const locationNo: number = await this.store
            .pipe(
                select(selectors.getCartLocationNo),
                take(1),
            ).toPromise();

        return this.navigateToLocation(locationNo);
    }

    public async navigateToCheckout(path: string = '/checkout', extras: NavigationExtras = {}): Promise<boolean> {
        return this.router.navigate([path ? path : '/checkout'], extras);
    }

    public removeURLQueryParams(): Promise<boolean> {
        return this.router.navigate([], {
            queryParams: {
                modal: undefined,
                itemId: undefined,
            },
            queryParamsHandling: 'merge',
        });
    }

    public async handleOrderMoreNavigation(): Promise<boolean> {
        return this.navigateToCartsLocation();
    }

    public saveConfirmationUrlAndNavigateToOrderConfirmation(orderId: number, locationNo: number, url: string = '/order-confirmation') {
        const encr: string = this.queryParamsService.encryptOrderData(locationNo, orderId);

        const UrlObj = new Utils.OrderConfirmationUrlGenerator(`${url}?order=${encr}`);
        this.store.dispatch(
            actions.OnlineOrderSaveConfirmationUrlRequest({
                orderId,
                url: UrlObj.getUrl(),
            })
        );

        return this.router.navigate([url], {
            queryParams: {
                order: encr,
            }
        });
    }

    public navigateToOrderConfirmationView(orderId: number, locationNo: number, url: string = '/order-confirmation'): void {
        const encr: string = this.queryParamsService.encryptOrderData(locationNo, orderId);

        this.router.navigate([url], {
            queryParams: {
                order: encr
            }
        });
    }

    public async navigateToProfileView(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.store
                .pipe(
                    select(selectors.isMemberAuthorizedJWT),
                    take(1)
                ).subscribe(isAuthorized => {
                    if (isAuthorized) {
                        return this.router.navigate(['/account']);
                    }

                    reject('unauthorized');
                });
        });
    }

    public async navigateToProfileRewards(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.store
                .pipe(
                    select(selectors.isMemberAuthorizedJWT),
                    take(1)
                ).subscribe(isAuthorized => {
                    if (isAuthorized) {
                        return this.router.navigate(['/account/rewards']);
                    }

                    reject('unauthorized');
                });
        });
    }

    public async navigateToProfileLoyaltyHome(): Promise<boolean> {
        return this.store
            .pipe(
                select(selectors.isMemberAuthorizedJWT),
                take(1)
            ).toPromise()
            .then(isAuthorized => {
                if (isAuthorized) {
                    return this.router.navigate(['/account/loyalty']);
                }

                return this.router.navigate(['/loyalty']);
            });
    }

    public async navigateToHomeView(): Promise<boolean> {
        return this.store
            .pipe(
                select(selectors.isMemberAuthorizedJWT),
                take(1)
            ).toPromise()
            .then(isAuthorized => {
                switch (true) {
                    case this.config.appMode === IAppMode.LOYALTY_ONLY && isAuthorized === true:
                        return this.router.navigate(['/account/loyalty']);
                    case this.config.appMode === IAppMode.LOYALTY_ONLY && isAuthorized === false:
                        return this.router.navigate(['/loyalty']);
                    default:
                        return this.router.navigate(['/']);
                }
            });
    }
}
