import { createAction, props } from '@ngrx/store';
import {
    IWizzardSimpleItem,
    IWizzardMenuFlow,
    IWizzardMenuFlowItem,
    IWizzardError,
    ICartMenuFlow,
    ICartSimpleItem,
    IMenuFlowDetailsModel
} from '../interface';

export const WizzardCancel = createAction(
    '[Wizzard] Cancel',
    props<{ modalId: number; }>(),
);

export const WizzardValidate = createAction(
    '[Wizzard] Validate',
    props<{ errors: IWizzardError[]; }>(),
);

/* Setting up wizzard */
export const WizzardSetupItem = createAction(
    '[Wizzard] Setup item in wizzard state',
    (locationNo: number, item: ICartMenuFlow | ICartSimpleItem | APICommon.IOnlineMenuProductResponseModel, modalId: number = null) => ({ locationNo, item, modalId }),
);

export const WizzardSilentSetupItem = createAction(
    '[Wizzard] Setup item in wizzard state - add as is',
    (locationNo: number, item: ICartMenuFlow | ICartSimpleItem | APICommon.IOnlineMenuProductResponseModel) => ({ locationNo, item }),
);

export const WizzardStepMenuFlowInit = createAction(
    '[Wizzard] Step menu flow init',
    (locationNo: number, item: ICartMenuFlow | ICartSimpleItem | APICommon.IOnlineMenuProductResponseModel, modalId: number = null) => ({ locationNo, item, modalId }),
);

export const WizzardStepMenuFlowRequestData = createAction(
    '[Wizzard] Step menu flow request data',
    props<{ locationNo: number; item: ICartMenuFlow | ICartSimpleItem | APICommon.IOnlineMenuProductResponseModel; modalId: number; }>(),
);

export const WizzardStepMenuFlowActivateProducts = createAction(
    '[Wizzard] Step menu flow activate products',
    props<{ locationNo: number; item: ICartMenuFlow | ICartSimpleItem | APICommon.IOnlineMenuProductResponseModel; modalId: number; }>(),
);

export const WizzardStepMenuFlowMount = createAction(
    '[Wizzard] Step menu flow mount',
    props<{ locationNo: number; menuFlowId: number; item: IWizzardMenuFlow; modalId: number; }>(),
);

export const WizzardStepSimpleItemMount = createAction(
    '[Wizzard] Step simple item mount',
    (item: IWizzardSimpleItem, modalId: number = null) => ({ item, modalId }),
);

export const WizzardMountMenuFlowUpsell = createAction(
    '[Wizzard] Menu flow upsell mount',
    props<{ item: IWizzardMenuFlow; modalId: number; }>(),
);

export const WizzardUnmountAll = createAction('[Wizzard] Unmount simple item and menu flows and clear all errors');

/* Setting up wizzard end */
export const WizzardSimpleItemIncrement = createAction(
    '[Wizzard] Increment simple item',
    (item: IWizzardSimpleItem, changeValue: number = 1, ) => ({ item, changeValue })
);

export const WizzardSimpleItemDecrement = createAction(
    '[Wizzard] Decrement simple item',
    (item: IWizzardSimpleItem, changeValue: number = 1, ) => ({ item, changeValue })
);

export const WizzardSimpleItemSpecialInstructions = createAction(
    '[Wizzard] Special instructions for simple item',
    (item: IWizzardSimpleItem, specialInstruction: string = null) => ({ item, specialInstruction }),
);

export const WizzardMenuFlowReplaceAllWithNew = createAction(
    '[Wizzard] Replace all products on specific page with new product',
    props<{ pageIdentifier: number; product: IWizzardMenuFlowItem; }>(),
);

export const WizzardRecalculatePricesForMenuFlowRequest = createAction(
    '[Wizzard] Request Recalculate prices for menu flow',
    props<{ menuFlowDetails: IMenuFlowDetailsModel; wizzardMenuFlowDetails: IWizzardMenuFlow; }>(),
);

export const WizzardRecalculatePricesForMenuFlowSuccessRequest = createAction(
    '[Wizzard] Success request recalculate prices for menu flow',
    props<{ menuFlowDetails: IMenuFlowDetailsModel; wizzardMenuFlowDetails: IWizzardMenuFlow; priceObj: OLO.Ordering.IPricingMenuFlow; }>(),
);

export const WizzardRecalculatePricesForMenuFlowErrorRequest = createAction(
    '[Wizzard] Error request recalculate prices for menu flow',
    props<{ menuFlowDetails: IMenuFlowDetailsModel; wizzardMenuFlowDetails: IWizzardMenuFlow; ex?: any; }>(),
);

export const WizzardMenuFlowIncrement = createAction(
    '[Wizzard] Increment menu flow',
    (changeValue: number = 1) => ({ changeValue })
);

export const WizzardMenuFlowDecrement = createAction(
    '[Wizzard] Decrement menu flow',
    (changeValue: number = 1) => ({ changeValue })
);

export const WizzardMenuFlowSpecialInstructions = createAction(
    '[Wizzard] Special instructions menu flow',
    (specialInstructions: string = null) => ({ specialInstructions })
);


/* Menu flow single items */
export const WizzardMenuFlowAddProduct = createAction(
    '[Wizzard] Add PRODUCT to specific page',
    props<{ pageIdentifier: number; product: IWizzardMenuFlowItem; }>(),
);

export const WizzardMenuFlowRemoveProduct = createAction(
    '[Wizzard] Remove PRODUCT to specific page',
    props<{ pageIdentifier: number; productId: number; }>(),
);

export const WizzardMenuFlowProductDecrement = createAction(
    '[Wizzard] Decrement PRODUCT in specific page',
    (pageIdentifier: number, productId: number, changeValue: number = 1) => ({ pageIdentifier, productId, changeValue }),
);

export const WizzardMenuFlowProductIncrement = createAction(
    '[Wizzard] Increment PRODUCT in specific page',
    (pageIdentifier: number, productId: number, changeValue: number = 1) => ({ pageIdentifier, productId, changeValue }),
);

/* Menu flow modifiers */
export const WizzardMenuFlowSelectModifier = createAction(
    '[Wizzard] Select modifier',
    props<{ modifier: APICommon.IIngredientModifierExtended; pageIdentifier: number; productId: number; }>(),
);

/* Upsell */
export const WizzardMenuFlowUpsellSetupRequest = createAction(
    '[Wizzard] Request upsell setup',
    (menuFlowId: number, locationNo: number, modalId: number = new Date().getTime()) => ({ menuFlowId, locationNo, modalId }),
);

/* Setting up wizzard */
export const WizzardAddVoucher = createAction(
    '[Wizzard] Add voucher',
    (locationNo: number, modalId: number = null) => ({ locationNo, modalId }),
);
