import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { ProductRestrictionsEffects } from './product-restrictions.effects';
import { productRestrictionsReducer } from './product-restrictions.reducer';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature('productRestrictions', productRestrictionsReducer),
        EffectsModule.forFeature([
            ProductRestrictionsEffects
        ]),
    ],
    exports: [],
    providers: [],
})
export class ProductRestrictionsStateModule { }
