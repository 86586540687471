import { Inject, Injectable } from '@angular/core';
import { take, filter } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';

import { MembersController } from '@shared/core/controllers/members';
import { ModalsService } from '@shared/core';
import * as Tokens from '@shared/core/tokens';
import * as Services from '@shared/core/services';

@Injectable({
    providedIn: 'root',
})
export class ReturningMemberService {

    public hasModalBeenDisplayed = false;

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private readonly _membersController: MembersController,
        private readonly _modalService: ModalsService,
        private readonly _sessionService: Services.SessionService,
        private router: Router
    ) { }

    public openLoginModalForReturningMember(): void {
        if (!this._config.enableQuickSignInForReturningMembers) return;

        let sessionExists = false;
        this._sessionService.getSession()
            .pipe(take(1))
            .subscribe(res => {
                if (res) {
                    sessionExists = true;
                }
            });

        if (sessionExists) return;

        const cellNumber = localStorage.getItem('user.cellNumber');
        const countryId = localStorage.getItem('user.countryId');

        if (!cellNumber || !countryId) return;

        if (!isNaN(+cellNumber) && !isNaN(+countryId)) {
            this.showAuthModal(countryId, cellNumber);
        }

    }

    private showAuthModal(countryId: string, cellNumber: string): void {
        const pathsWithoutAuthModal = ['reset-password', 'email-confirmation', 'order-confirmation'];
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            take(1))
            .subscribe((event: NavigationEnd) => {
                if (!pathsWithoutAuthModal.some(path => event.url.includes(path))) {
                    this._membersController.initSignIn(`${countryId}:${cellNumber}`);
                    this._modalService.show({
                        type: 'auth'
                    });
                    this.hasModalBeenDisplayed = true;
                }
            });
    }
}
