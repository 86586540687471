import { Strings } from './strings.utils';
import { Numbers } from './numbers.utils';

export class Dates {
    public static readonly days: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    public static readonly months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    public static getWeekNumber(date: Date | string | number): number {
        const d = Dates.createDate(date);
        const firstDayOfYear = new Date(d.getFullYear(), 0, 1);
        const pastDaysOfYear = ((d as any) - (firstDayOfYear as any)) / 86400000;

        return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    }

    public static isToday(date: Date | string): boolean {
        return Dates.datesDiffInDays(new Date(), date) === 0;
    }
    public static datesDiffInDays(a: string | Date, b: string | Date): number {
        const _MS_PER_DAY: number = 1000 * 60 * 60 * 24;
        const d1 = Dates.createDate(a);
        const d2 = Dates.createDate(b);

        const utc1 = Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate());
        const utc2 = Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate());

        return Math.abs(Math.floor((utc2 - utc1) / _MS_PER_DAY));
    }

    public static descriptionDate(date: string | Date): string {
        //
        //  Will output 'Wednesday, September 12' - switch to moment in the future
        //
        const d: Date = typeof date === 'string' ? new Date(date) : date;
        const month: string = Strings.capitalizeFirstChar(Dates.months[d.getMonth()]);
        const day: string = Strings.capitalizeFirstChar(Dates.days[d.getDay()]);

        return `${day}, ${month} ${d.getDate()}`;
    }

    public static subtractYearsFromToday(yearsCount: number): Date {
        return new Date(new Date().setFullYear(new Date().getFullYear() - yearsCount));
    }

    public static getDaysInMonth(month, year): number {
        return new Date(year, month, 0).getDate();
    }

    public static minsToMiliseconds(minutes: number): number {
        return minutes * 60 * 1000;
    }

    public static millisecsToMins(millis): number {
        return Math.floor(millis / 60000);
    }

    public static toMilliseconds(date: string | number | Date): number {
        const newDate: Date = date instanceof Date ? (date as Date) : new Date(date as any);

        if (newDate.toString() === 'Invalid Date') {
            return null;
        }

        return newDate.getTime();
    }

    public static hoursFromDate(date: Date, meridemi: boolean = true): string {
        let hours: number = date.getHours();
        let minutes: number = date.getMinutes();
        let timeString: string = '';

        if (meridemi) {
            let hoursConverted = hours > 12 ? hours - 12 : hours;
            timeString += hoursConverted < 10 ? '0' + hoursConverted : `${hoursConverted}`;
        } else {
            timeString += hours < 10 ? '0' + hours : `${hours}`;
        }

        return (timeString += `:${minutes < 10 ? '0' + minutes : minutes}${meridemi ? (hours < 12 ? 'am' : 'pm') : ''}`);
    }

    public static getDayName(dayNo: OLO.Dates.DAY_OF_WEEK): string {
        return Dates.days[dayNo];
    }

    public static createDate(date: Date | string | null | number = null): Date {
        let d: Date;
        if (!date) {
            d = new Date();
        }

        if (typeof date === 'number') {
            d = new Date(date);
        }

        if (typeof date === 'string') {
            const a: number[] = (date as string).split(/[T\-:.]/gi).map((obj: string, index) => {
                obj = obj.replace('Z', '');
                if (index === 1) {
                    return +obj - 1;
                } else {
                    return +obj;
                }
            });

            if (a.length > 7) {
                a.length = 7;
            } else if (a.length < 7) {
                while (a.length < 7) {
                    a.push(0);
                }
            }

            d = new Date(a[0], a[1], a[2], a[3], a[4], a[5], a[6]);
        }

        if (date instanceof Date === true) {
            d = date as Date;
        }

        return d;
    }

    public static getLocalISOFormatDate(date: Date = new Date(), includeZ: boolean = false): string {
        if (date instanceof Date === false) return '';

        const year: number = date.getFullYear();
        const month: string = Numbers.leadingZero(date.getMonth() + 1);
        const day: string = Numbers.leadingZero(date.getDate());
        const hours: string = Numbers.leadingZero(date.getHours());
        const minutes: string = Numbers.leadingZero(date.getMinutes());
        const seconds: string = Numbers.leadingZero(date.getSeconds());
        let miliseconds: number | string = date.getMilliseconds();

        switch (true) {
            case miliseconds < 100 && miliseconds > 10:
                miliseconds = `0${miliseconds}`;
                break;
            case miliseconds < 10:
                miliseconds = `00${miliseconds}`;
                break;
            default:
                miliseconds = `${miliseconds}`;
        }

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${miliseconds}${includeZ ? 'Z' : ''}`;
    }

    public static createHoursIntFromDate(date: Date | string | number): number {
        if (!date) {
            return null;
        }
        let stringDate: string = date as string;

        if (date instanceof Date) {
            stringDate = Dates.getLocalISOFormatDate(date);
        }

        if (typeof date === 'number') {
            stringDate = Dates.getLocalISOFormatDate(new Date(date));
        }

        const long = stringDate.match(/(\d{2}:){2}\d{2}/g);
        const short = stringDate.match(/\d{2}:\d{2}/g);
        const finalString = short ? `${short[0]}:00` : long[0];

        return Number(finalString.replace(/:/g, ''));
    }

    public static isHourInHoursRange(
        toCheck: Date | string | number,
        from: Date | string | number,
        to: Date | string | number,
        equal: null | 'from' | 'to' | 'both' = 'both',
    ): boolean {
        if (!toCheck || !from || !to) return false;

        const n: number = Dates.createHoursIntFromDate(toCheck);
        const f: number = Dates.createHoursIntFromDate(from);
        const t: number = Dates.createHoursIntFromDate(to);
        if (!equal) return n > f && n < t;

        if (equal === 'from') return n >= f && n < t;

        if (equal === 'to') return n > f && n <= t;

        return n >= f && n <= t;
    }

    public static getClosedTimeFromHourString(hours: string): string {
        /* will convert 22:59:00 -> 23:00:00, or 23:59:00 -> 00:00:00 */
        const strArr: string[] = hours.split(/:/g);
        if (strArr.length !== 3 || hours.length !== 8) return null;

        if (strArr[1] === '59') {
            strArr[1] = '00';

            strArr[0] = `${+strArr[0] + 1}`;
        }

        if (strArr[0] === '24') {
            strArr[0] = '00';
        }

        return strArr.join(':');
    }

    public static stringHourToNumber(hour: string): number {
        if (typeof hour !== 'string') return hour;

        return Number(hour.replace(/:/g, ''));
    }

    public static isHourInTimeRange(pickupHour: string, startTime: string, endTime: string) {
        if (!pickupHour) return false;

        const startTimeNo: number = Dates.stringHourToNumber(startTime || pickupHour);
        const endTimeNo: number = Dates.stringHourToNumber(endTime || pickupHour);
        const pickupHourNo: number = Dates.stringHourToNumber(pickupHour);

        return pickupHourNo <= endTimeNo && pickupHourNo >= startTimeNo;
    }

    public static roundDateToMinutes(date: Date, method: 'round' | 'ceil' = 'round'): Date {
        return new Date(Math[method](date.getTime() / 60000) * 60000);
    }
}
