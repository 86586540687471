import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store, select } from '@ngrx/store';

import * as State from '@shared/state';

import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';


import { DrawCollapseService } from './draw-collapse.shared.service';

@Injectable()
export class DrawExpandResolver implements Resolve<boolean> {
    constructor(
        private _drawCollapseService: DrawCollapseService,
        private _store: Store<State.IStateShared>
    ) { }

    resolve(): Observable<any> | Promise<any> | any {
        return new Promise(resolve => {
            if (this._drawCollapseService.hasInitialized) {
                this._store.pipe(
                    select(State.getLoyaltyAppSettings),
                    filter(loyaltyState => loyaltyState.hasFailed || loyaltyState.hasSucceeded),
                    take(1),
                ).subscribe(() => {
                    setTimeout(() => {
                        this._drawCollapseService.tempUnlockScrollOnToggle();
                        this._drawCollapseService.toggleDraw(true);

                        return resolve(true);
                    }, this._drawCollapseService.transitionTime);
                });

            } else {
                this._drawCollapseService.tempUnlockScrollOnToggle();
                this._drawCollapseService.toggleDraw(true);

                return resolve(true);
            }

        });
    }
}
