import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { OrderTypesEffects } from './order-types.effects';
import { orderTypesReducer } from './order-types.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('orderTypes', orderTypesReducer),
        EffectsModule.forFeature([
            OrderTypesEffects
        ]),
    ],
})
export class OrderTypesStateModule { }
