import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { VenueImagesEffects } from './venues-images.effects';
import { venueImagesReducer } from './venues-images.reducer';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature('venuesImages', venueImagesReducer),
        EffectsModule.forFeature([
            VenueImagesEffects
        ]),
    ],
    exports: [],
    providers: [],
})
export class VenuesImagesStateModule { }
