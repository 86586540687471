import { ActionReducer, Action } from '@ngrx/store';
import * as StateModels from '../interface';

import * as actions from '../actions';

export function __devMetaReducer(reducer: ActionReducer<StateModels.IStateShared>): ActionReducer<StateModels.IStateShared> {
    return function newReducer(state: StateModels.IStateShared, action: Action): StateModels.IStateShared {
        // if (action.type === '@ngrx/router-store/navigated') {
        //     console.trace('nav', action);
        // }
        // if (action.type === actions.CartReset.type) {
        //     console.trace('cart reset', action);
        // }

        switch (action.type) {
            case '[__DevSetState] Set state':
                return {
                    ...state,
                    ...(action as any).state,
                };

            default: {
                return reducer(state, action);
            }
        }
    };
}
