import { FormGroup } from '@angular/forms';

export class Forms {
    public static extractValues(form: FormGroup): { [key: string]: any; } {
        return Object.keys(form.controls).reduce((acc, controlName) =>
            /* TODO - support nested stuff */
            ({
                ...acc,
                [controlName]: form.get(controlName).value,
            })
        , {});
    }

    public static labelToName(str: string): string {
        if (!str || typeof str !== 'string') return null;

        return str.toLowerCase().replace(/[^a-z]/g, '');
    }
}
