import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { OrderTypesImagesEffects } from './order-types-images.effects';
import { orderTypesImagesReducer } from './order-types-images.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('orderTypesImages', orderTypesImagesReducer),
        EffectsModule.forFeature([
            OrderTypesImagesEffects
        ]),
    ],
})
export class OrderTypesImagesStateModule { }
