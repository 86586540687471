import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DevService {
    private _data: BehaviorSubject<any> = new BehaviorSubject('--CLEAR');

    public getLogsData$(): BehaviorSubject<any> {
        return this._data;
    }

    public log(data: any): void {
        this._data.next(data);
    }

    public clear(): void {
        this._data.next('--CLEAR');
    }
}
