import { createReducer, on, Action } from '@ngrx/store';

import * as actions from './venues-images.actions';
import * as Utils from '@shared/core/utils';
import { IVenuesImages } from './venues-images.interface';

const initialState: IVenuesImages = {
    default: [],
    forList: [],
    forDetails: [],
    forWeb: [],
    forWebThumb: [],
    forMobile: [],
};
export const venueImagesReducerFn = createReducer(
    initialState,
    on(
        actions.VenueImagesRequest,
        (state, action) => {
            let imagesType: string = Utils.Images.toImageTypeString(action.imageType);

            const notExistingImages: number[] = action.ids.filter(id => !state[imagesType].find((img: OLO.Common.IImageStateObj) => img.Id === id));

            return {
                ...state,
                [imagesType]: [
                    ...state[imagesType].map(image => {
                        if (action.ids.includes(image.Id)) {
                            return {
                                ...image,
                                isDownloading: true,
                                hasSucceeded: false,
                                hasFailed: false,
                            };
                        }

                        return image;
                    }),

                    ...notExistingImages.map(id => ({
                        Id: id,
                        isDownloading: false,
                        hasSucceeded: false,
                        hasFailed: false,
                        data: null,
                    }))

                ]
            };
        }
    ),
    on(
        actions.VenueImagesSuccessRequest,
        (state, action) => {
            let imagesType: string = Utils.Images.toImageTypeString(action.imageType);

            return {
                ...state,
                [imagesType]: state[imagesType].map(image => {
                    const imageIsUpdating = action.ids.includes(image.Id);
                    if (imageIsUpdating) {
                        const newImage = action.payload.find(obj => obj.ParentId === image.Id);

                        return {
                            ...image,
                            isDownloading: false,
                            hasSucceeded: true,
                            hasFailed: true,
                            data: newImage || null,
                        };
                    }

                    return image;
                })
            };
        }
    ),
    on(
        actions.VenueImagesErrorRequest,
        (state, action) => {
            let imagesType: string = Utils.Images.toImageTypeString(action.imageType);

            return {
                ...state,
                [imagesType]: state[imagesType].map(image => {
                    if (action.ids.includes(image.Id)) {
                        return {
                            ...image,
                            isDownloading: false,
                            hasSucceeded: false,
                            hasFailed: true,
                        };
                    }

                    return image;
                })
            };

        }
    ),

);

export function venueImagesReducer(state: IVenuesImages | undefined, action: Action) {
    return venueImagesReducerFn(state, action);
}
