import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './cart.actions';

import { ICart } from './cart.interface';

import * as State from '@shared/state/interface';
import { IActivatedOrderVoucherDetails } from '@shared/state/interface';

const initialState: ICart = {
    locationNo: null,
    collectionTypeId: null,
    pickupTime: null,
    onlineMenu: null,
    itemsMenuFlow: [],
    itemsSimple: [],
    activatedVouchers: []
    // pendingCartClear: false,

};

export const cartReducerFn = createReducer(
    initialState,

    on(
        actions.CartLoad,
        (state, action) => {
            let json = action.cart;
            if (!json) return {
                ...state,
            };
            /* Convert string dates to object dates */
            if (json.pickupTime && json.pickupTime.Date && json.pickupTime.PlaceOrderTimeout) {
                json = {
                    ...json,
                    pickupTime: {
                        ...json.pickupTime,
                        Date: new Date(json.pickupTime.Date),
                        PlaceOrderTimeout: new Date(json.pickupTime.PlaceOrderTimeout),
                    }
                };
            }

            return json;
        }
    ),
    on(
        actions.CartSetOnlineMenu,
        (state, action) => ({
            ...state,
            onlineMenu: JSON.parse(JSON.stringify(action.onlineMenu)),
        })
    ),
    on(
        actions.CartSetPickupTime,
        (state, action) => ({
            ...state,
            pickupTime: {
                ...action.pickupTime,
            }
        })
    ),
    on(
        actions.CartSetLocationNo,
        (state, action) => ({
            ...state,
            locationNo: action.locationNo
        })
    ),
    on(
        actions.CartSetCollectionTypeId,
        (state, action) => ({
            ...state,
            collectionTypeId: action.collectionTypeId
        })
    ),
    // on(
    //     actions.CartSetPendingClear,
    //     (state, action) => ({
    //         ...state,
    //         pendingCartClear: true,
    //     })
    // ),
    on(
        actions.CartReset,
        (state, action) => ({
            ...state,
            itemsMenuFlow: [],
            itemsSimple: [],
            // pendingCartClear: false,

            /* Here? */
            pickupTime: null,
            locationNo: null,
            onlineMenu: null,
        })
    ),
    on(
        actions.CartRemoveAllItems,
        (state, action) => ({
            ...state,
            itemsMenuFlow: [],
            itemsSimple: [],
        })
    ),
    on(
        actions.CartSimpleItemAdd,
        (state, action) => {
            let found: boolean = false;
            const newItemsArray = state.itemsSimple.map((obj: State.ICartSimpleItemExtended) => {
                if (obj.Plu === action.item.Plu && obj.UnitPrice === action.item.UnitPrice && obj.SpecialInstructions === action.item.SpecialInstructions) {
                    found = true;

                    return {
                        ...obj,
                        Quantity: obj.Quantity + action.item.Quantity,
                    };
                }

                return obj;
            });

            if (!found) {
                newItemsArray.push({
                    ...action.item
                });
            }

            return {
                ...state,
                itemsSimple: newItemsArray
            };
        }
    ),
    on(
        actions.CartSimpleItemUpdate,
        (state, action) => ({
            ...state,
            itemsSimple: state.itemsSimple.map(item => {
                if (item._Id === action.item._Id) {
                    return {
                        ...item,
                        ...action.item,
                    };
                }

                return item;
            }),
        })
    ),
    on(
        actions.CartSimpleItemRemove,
        (state, action) => ({
            ...state,
            itemsSimple: state.itemsSimple.filter(item => item._Id !== action.tempItemId)
        })
    ),
    on(
        actions.CartSimpleItemDecrement,
        (state, action) => ({
            ...state,
            itemsSimple: state.itemsSimple.map(item => {
                if (item._Id === action.tempItemId) {
                    return {
                        ...item,
                        Quantity: item.Quantity - action.changeValue,
                    };
                }

                return item;
            })
        })
    ),
    on(
        actions.CartSimpleItemIncrement,
        (state, action) => ({
            ...state,
            itemsSimple: state.itemsSimple.map(item => {
                if (item._Id === action.tempItemId) {
                    return {
                        ...item,
                        Quantity: item.Quantity + action.changeValue,
                    };
                }

                return item;
            })
        })
    ),
    on(
        actions.CartMenuFlowAdd,
        (state, action) => ({
            ...state,
            itemsMenuFlow: [
                ...state.itemsMenuFlow,
                {
                    ...action.item,
                }
            ]
        })
    ),
    on(
        actions.CartMenuFlowUpdate,
        (state, action) => ({
            ...state,
            itemsMenuFlow: state.itemsMenuFlow.map(item => {
                if (item._Id === action.item._Id) {
                    return {
                        ...item,
                        ...action.item,
                    };
                }

                return item;
            })
        })
    ),
    on(
        actions.CartMenuFlowRemove,
        (state, action) => ({
            ...state,
            itemsMenuFlow: state.itemsMenuFlow.filter(item => item._Id !== action.tempId),
        })
    ),
    on(
        actions.CartMenuFlowDecrement,
        (state, action) => ({
            ...state,
            itemsMenuFlow: state.itemsMenuFlow.map(item => {
                if (item._Id === action.tempId) {
                    return {
                        ...item,
                        Quantity: item.Quantity - action.changeValue,
                    };
                }

                return item;
            })
        })
    ),
    on(
        actions.CartMenuFlowIncrement,
        (state, action) => ({
            ...state,
            itemsMenuFlow: state.itemsMenuFlow.map(item => {
                if (item._Id === action.tempId) {
                    return {
                        ...item,
                        Quantity: item.Quantity + action.changeValue,
                    };
                }

                return item;
            })
        })
    ),
    on(
        actions.CartAddActivatedVoucher,
        (state, action) => ({
            ...state,
            activatedVouchers: action.payload.ActivatedVouchers
        })
    ),
    on(
        actions.CartRemoveActivatedVoucher,
        (state, action) => ({
            ...state,
            activatedVouchers: []
        })
    ),
);

export function cartReducer(state: ICart | undefined, action: Action) {
    return cartReducerFn(state, action);
}
