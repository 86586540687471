import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as Services from '@shared/core/services';

import { Observable, of } from 'rxjs';
import { switchMap, catchError, map, mergeMap } from 'rxjs/operators';


@Injectable()
export class LatestTransactionsEffects {
    @Effect() public resetLoyaltyProgramOnSignOut$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.MemberSignOut,
            ),
            switchMap(() => of(actions.LatestTransactionsReset()))
        );

    @Effect() public requestLatestTransactions$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.LatestTransactionsRequest
            ),
            mergeMap(({ memberId }) => this._membersService.getLatestTransactions()
                .pipe(
                    map(payload => actions.LatestTransactionsSuccessRequest({ memberId, payload })),
                    catchError(ex => {
                        console.error('getLatestTransactionsForMember', ex);

                        return of(actions.LatestTransactionsErrorRequest({ memberId, ex }));
                    })
                ))
        );

    constructor(
        private _actions$: Actions,
        private _membersService: Services.MembersService,
    ) { }
}
