/* https://www.npmjs.com/package/js-cookie */
import { Injectable } from '@angular/core';
import * as Cookies from 'js-cookie';

export interface CookiesOptions {
    expires?: number;
    path?: string;
    domain?: string;
    secure?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class CookieService {

    private defaults: CookiesOptions = {
        expires: 30,
        path: '/',
    };

    private _mergeOptions(options: CookiesOptions | number | null): CookiesOptions {
        /* Handle session Cookie */
        if (options === null) {
            const opts = { ...this.defaults };
            delete opts.expires;

            return opts;
        }

        /* Making stuff a bit more flexible - provide only expires in days as number instead of CookiesOptions object */
        if (typeof options === 'number' && !Number.isNaN(options)) {
            return { ...this.defaults, expires: options };
        }

        /* Handle regular object */
        return { ...this.defaults, ...(options as CookiesOptions) };
    }

    public set(name: string, value: string | { [key: string]: any; }, options: CookiesOptions | number = {}): string {
        return Cookies.set(name, value, this._mergeOptions(options));
    }

    public get(name?: string): string | { [key: string]: any; } | undefined {
        return Cookies.get(name ? name : undefined);
    }

    public getJSON(name?: string): { [key: string]: any; } | undefined {
        return Cookies.getJSON(name ? name : undefined);
    }

    public getAll(): { [key: string]: any; } | undefined {
        return Cookies.get();
    }

    public remove(name: string, options: CookiesOptions = {}): void {
        return Cookies.remove(name, { path: this.defaults.path, ...options });
    }

    public removeAll(): void {
        const cookiesObject: { [key: string]: any; } | undefined = Cookies.get();
        if (cookiesObject) {
            for (const prop in cookiesObject) {
                this.remove(prop);
            }
        }
    }

}
