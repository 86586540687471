
import { Strings } from './strings.utils';

export class Storage {

    public static set(name: string, value: number | null | undefined | string | { [key: string]: any } | any[], storageType: string = 'localStorage'): boolean {
        try {
            window[storageType].setItem(name, Strings.toString(value));
            return true;
        } catch (ex) {
            console.error(ex);
            return false;
        }
    }

    public static getItem(name: string, storageType: string = 'localStorage'): string | null {
        try {
            return window[storageType].getItem(name);
        } catch (ex) {
            console.error(ex);
            return null;
        }
    }

    public static remove(name: string, storageType: string = 'localStorage'): boolean {
        try {
            window[storageType].removeItem(name);
            return true;
        } catch (ex) {
            console.error(ex);
            return false;
        }
    }

    public static clear(storageType: string = 'localStorage'): boolean {
        try {
            window[storageType].clear();
            return true;
        } catch (ex) {
            console.error(ex);
            return false;
        }
    }
}
