import { Action, createAction, props } from '@ngrx/store';
import { IRouterNavigationPayload, IRouterCancelPayload } from './router.interface';
/* https://ngrx.io/guide/router-store */
//
//  Don't dispatch any of these actions explicitly.
//  These will be dispatched automaticaly by ngrx-router-store.
//  Just use these for beter typescript support.
//

export const ROUTER_REQUEST = '@ngrx/router-store/request';
export const ROUTER_NAVIGATION = '@ngrx/router-store/navigation';

export const ROUTER_NAVIGATED = '@ngrx/router-store/navigated';
export const ROUTER_CANCEL = '@ngrx/router-store/cancel';
export const ROUTER_ERROR = '@ngrx/router-store/error';
// export class RouterNavigation {
//     public type: string = ROUTER_NAVIGATION;
//     constructor(
//         public payload: IRouterNavigationPayload,
//     ) { }
// }

export const RouterNavigation = createAction(
    ROUTER_NAVIGATION,
    props<{ payload?: IRouterNavigationPayload; }>()
);

export const RouterCancel = createAction(
    ROUTER_CANCEL,
    props<{ payload?: IRouterCancelPayload; }>()
);

