export class LoyaltyProgramCodeName {
    constructor(
        private _programType: APICommon.LOYALTY_PROGRAM_TYPE,
    ) {
        if (!_programType || typeof _programType !== 'number') {
            throw new Error(`Invalid programType argument`);
        }
    }

    public getProgramCodeName(): string {
        switch (this._programType) {
            case APICommon.LOYALTY_PROGRAM_TYPE.HOT_DRINK:
                return 'hot';
            case APICommon.LOYALTY_PROGRAM_TYPE.COLD_DRINK:
                return 'cold';
            case APICommon.LOYALTY_PROGRAM_TYPE.BREAD:
                return 'bread';
            default:
                throw new Error('Invalid programType: ' + this._programType);
        }
    }
}
