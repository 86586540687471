import { Injectable } from '@angular/core';
import { Store, select, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Services from '@shared/core/services';
import * as Utils from '@shared/core/utils';

import * as StateModels from '../interface';

import { Observable, of, never } from 'rxjs';
import { catchError, map, switchMap, take, filter } from 'rxjs/operators';
import { ILoyaltyAppCountryAssignmentModel, ILoyaltyAppModel } from '../interface';

@Injectable()
export class AppSettingsEffects {

    @Effect() public requestApiSettings$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.AppSettingsRequest
            ),
            switchMap(action => this._loyaltyAppService.apiGetLoyaltyAppInfo()
                .pipe(
                    map((payload: ILoyaltyAppModel) => {
                        payload.CountryAssignments = payload.CountryAssignments.sort(
                            (a: ILoyaltyAppCountryAssignmentModel, b: ILoyaltyAppCountryAssignmentModel) => (a.Name > b.Name) ? 1 : (a.Name < b.Name) ? -1 : 0);

                        return actions.AppSettingsSuccessRequest({ payload });
                    }),
                    catchError(ex => {
                        console.error('Loyalty app settings request error:', ex);

                        return of(actions.AppSettingsErrorRequest({ ex }));
                    })
                )),
        );

    constructor(
        private _actions$: Actions,
        private _loyaltyAppService: Services.LoyaltyAppService,
    ) { }
}
