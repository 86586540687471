import { Injectable } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';
import * as Services from '@shared/core/services';
import * as State from '@shared/state/interface';
import * as StateModels from '../interface';

import { Observable, of, never, iif } from 'rxjs';
import { map, catchError, switchMap, mergeMap, withLatestFrom, filter, take } from 'rxjs/operators';

@Injectable()
export class OnlineMenuProductsImagesEffects {
    private _imgParams: APICommon.IImagesGetParams = { width: 150, height: 150 };

    @Effect() public requestProductImageOnDirectEnter$: Observable<Action> = this._actions$.pipe(
        ofType(
            actions.WizzardStepSimpleItemMount,
            actions.WizzardStepMenuFlowMount
        ),
        withLatestFrom(this._store.pipe(select(selectors.getOnlineMenu))),
        switchMap(([action, onlineMenu]) => {
            const isMenuFlow = action.item.MenuFlowId ? true : false;
            const id = isMenuFlow ? action.item.MenuFlowId : (action.item as State.IWizzardSimpleItem).ProductId;

            return this._store.pipe(
                select(selectors.getImageForProduct(id)),
                take(1),
                filter((image) => Boolean(image) === false),
                switchMap(() => {
                    const ProductIdPage = onlineMenu.data.Pages.find((page) => page.Products.find((product) => isMenuFlow ? product.MenuFlowId === id : product.ProductId === id));
                    if (ProductIdPage) {
                        return [
                            actions.OnlineMenuProductsImagesRequest({
                                params: this._imgParams,
                                pageId: ProductIdPage.Id,
                            }),
                        ];
                    }
                }),
            );
        }),
    );

    @Effect() public onCartItemEditRequestImageForItem$: Observable<Action> = this._actions$.pipe(
        ofType(actions.CartEditItem),
        withLatestFrom(this._store.pipe(select(selectors.getCart))),
        switchMap(([action, cart]) => {
            const itemId: number = action.item.MenuFlowId ? action.item.MenuFlowId : (action.item as State.ICartSimpleItemExtended).ProductId;
            const isMenuFlow: boolean = !!action.item.MenuFlowId;

            if (!cart.onlineMenu || !cart.onlineMenu.Pages) return never();

            return cart.onlineMenu.Pages.map((page) => actions.OnlineMenuProductsImagesRequest({ params: this._imgParams, pageId: page.Id }));
        }),
    );

    @Effect() public onPageImagesRequest$: Observable<Action> = this._actions$.pipe(
        ofType(actions.OnlineMenuProductsImagesRequest),
        mergeMap(({ params, pageId }) =>
            this._imagesService.getImagesForOnlineMenuPageProducts(params, pageId).pipe(
                map((payload) => actions.OnlineMenuProductsImagesSuccessRequest({ pageId, payload })),
                catchError((ex) => of(actions.OnlineMenuProductsImagesErrorRequest({ pageId, ex }))),
            ),
        ),
    );

    constructor(private _actions$: Actions, private _imagesService: Services.ImagesService, private _store: Store<StateModels.IStateShared>) {}
}
