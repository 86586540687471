import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { locationsFiltersReducer } from './locations-filters.reducer';
import { LocationFiltersEffects } from './locations-filters.effects';
@NgModule({
    imports: [
        StoreModule.forFeature('locationsFilters', locationsFiltersReducer),
        EffectsModule.forFeature([
            LocationFiltersEffects
        ]),
    ],
})
export class LocationsFiltersStateModule { }
