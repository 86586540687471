export abstract class Model<T> {
    protected _model: T = {} as T;

    public toJson(): T {
        try {
            return JSON.parse(JSON.stringify(this._model));
        } catch (error) {
            console.error('Unable to parse model', this._model);

            return this._model;
        }
    }
}
