import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';

import * as State from '@shared/state';

import { Observable } from 'rxjs';
import { auditTime, pluck, filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class OnlineMenuController {
    constructor(
        private _store: Store<State.IStateShared>,
    ) { }
    public getPageDetails$(pageId: number, imageType: OLO.Enums.IMAGE_TYPE = OLO.Enums.IMAGE_TYPE.ForWeb) {
        return this._store
            .pipe(
                select(selectors.getOnlineMenuPageDetails(pageId, imageType))
            );
    }

    public onlineMenu$(): Observable<State.IOnlineMenu> {
        return this._store.select(selectors.getOnlineMenu);
    }

    public onlineMenuPages$(): Observable<APICommon.IOnlineMenuPageResponseModel[]> {
        return this._store.select(selectors.getOnlineMenuPages);
    }

    public onlineMenuPagesFiltered$(): Observable<APICommon.IOnlineMenuPageResponseModel[]> {
        return this._store.select(selectors.getOnlineMenuPagesFiltered)
            .pipe(
                auditTime(0)
            );
    }

    public getModalDataForOnlineMenuSingleProductItem(productId: number): Observable<OLO.Ordering.IDataForOnlineMenuSingleProductItem> {
        return this._store.pipe(
            select(selectors.getModalDataForOnlineMenuItem(productId, false))
        );
    }

    public getStatsForSingleProductItem(productId: number): Observable<OLO.Components.IStatsComponentInput> {
        return this._store.pipe(
            select(selectors.getStatsForSingleProduct(productId))
        );
    }

    public canShowOnlineMenuForCurrentLocation$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.canShowOnlineMenuForCurrentLocation)
            );
    }

    public getCurrentOnlineMenuId$(): Observable<number> {
        return this._store
            .pipe(
                select(selectors.getOnlineMenuData),
                filter(menuData => !!menuData),
                pluck('Id')
            );
    }
}
