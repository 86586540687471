import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { OnlineMenuPagesImagesEffects } from './online-menu-pages-images.effects';
import { onlineMenuPagesImagesReducer } from './online-menu-pages-images.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature('onlineMenuPagesImages', onlineMenuPagesImagesReducer),
        EffectsModule.forFeature([
            OnlineMenuPagesImagesEffects
        ]),
    ],
})
export class OnlineMenuPagesImagesStateModule { }
