import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import * as State from '@shared/state/interface';
import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';
import * as APIV2Services from '@api/v2/services';

import * as actions from '@shared/state/actions';
import * as selectors from '@shared/state/selectors';

import { ConvergePaymentProviderService } from './paymentProviders/converge.payment-provider.shared.service';
import { CardConnectPaymentProviderService } from './paymentProviders/card-connect.payment-provider.shared.service';
import { PaymentExpressPaymentProviderService } from './paymentProviders/payment-express.payment-provider.shared.service';
import { FatZebraPaymentProviderService } from './paymentProviders/fat-zebra.payment-provider.shared.service';
import { AdyenPaymentProviderService } from './paymentProviders/adyen.payment-provider.shared.service';
import { StripePaymentProviderService } from './paymentProviders/stripe.payment-provider.shared.service';

import { Observable, throwError, of } from 'rxjs';
import { catchError, flatMap, map, filter, take, delay, switchMap, tap } from 'rxjs/operators';
import { CreditCardsMapper } from '@shared/core/mappers/credit-cards.shared.mapper';

@Injectable({
    providedIn: 'root',
})
export class CreditCardsService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public store: Store<State.IStateShared>,
        public apiV2Services: APIV2Services.MembersService,
        public httpClient: HttpClient,
        @Optional() public convergePaymentProviderService: ConvergePaymentProviderService,
        @Optional() public cardConnectPaymentProviderService: CardConnectPaymentProviderService,
        @Optional() public paymentExpressPaymentProviderService: PaymentExpressPaymentProviderService,
        @Optional() public fatZebraPaymentProviderService: FatZebraPaymentProviderService,
        @Optional() public adyenPaymentProviderService: AdyenPaymentProviderService,
        @Optional() public stripePaymentProviderService: StripePaymentProviderService,
        @Optional() public actions$: Actions,
    ) {
    }

    public requestAdyenPreconfigurationSetup(locationNo: number): Observable<State.IAdyenSettingsResponse> {
        return this.store
            .pipe(
                select(selectors.getLoyaltyAppSettings),
                filter(appSettings => appSettings.data !== null),
                take(1),
                switchMap(appSettings => this.adyenPaymentProviderService.requestConfig(locationNo, appSettings.data?.AppSettings?.DefaultAdyenSettings)),
                catchError(ex => {
                    console.error('Unable to request config data for Adyen PP.', ex);

                    return throwError(ex);
                })
            );
    }

    public requestStripePreconfigurationSetup(locationNo: number): Observable<State.IStripeSettingsResponse> {
        return this.stripePaymentProviderService.requestConfig(locationNo);
    }

    public requestCardTokenForDefaultPaymentProvider(
        cardData: OLO.CreditCards.ICreditCardDetails,
        locationNo: number = null
    ): Observable<APICommon.IPaymentProviderDefaultConfigResponse> {
        return this.store
            .pipe(
                select(selectors.getLoyaltyAppSettings),
                filter(appSettings => appSettings.data !== null),
                take(1),
                flatMap(appSettings => {
                    switch (true) {
                        case this.config.paymentProvider === OLO.Enums.PAYMENT_PROVIDER.CONVERGE
                            && appSettings.data.AppSettings.DefaultConvergeSettings !== null
                            && this.convergePaymentProviderService !== null:

                            return this.convergePaymentProviderService.requestCardToken({
                                ssl_card_number: cardData.cardNumber,
                                ssl_exp_date: Utils.CreditCards.dateToShowFormat(cardData.expiryDate, ''),
                                ssl_add_token: 'Y',
                            }, locationNo, appSettings.data.AppSettings.DefaultConvergeSettings)
                                .pipe(
                                    map(convergeResponse => ({
                                        token: convergeResponse.ssl_token
                                    }))
                                );

                        case this.config.paymentProvider === OLO.Enums.PAYMENT_PROVIDER.CARD_CONNECT
                            && appSettings.data.AppSettings.DefaultCardConnectSettings !== null
                            && this.cardConnectPaymentProviderService !== null:
                            return this.cardConnectPaymentProviderService
                                .requestCardToken(cardData.cardNumber, locationNo)
                                .pipe(
                                    map(cardConnectResponse => ({
                                        token: cardConnectResponse.cardsecure.data,
                                    }))
                                );

                        case this.config.paymentProvider === OLO.Enums.PAYMENT_PROVIDER.PAYMENT_EXPRESS
                            && appSettings.data.AppSettings.DefaultPaymentExpressSettings !== null
                            && this.paymentExpressPaymentProviderService !== null:
                            return this.paymentExpressPaymentProviderService
                                .requestCardToken(locationNo, appSettings.data.AppSettings.DefaultPaymentExpressSettings)
                                .pipe(
                                    map(paymentExpressResponse => ({
                                        token: paymentExpressResponse.SessionToken,
                                        directPostUrl: paymentExpressResponse.Url
                                    }))
                                );

                        case this.config.paymentProvider === OLO.Enums.PAYMENT_PROVIDER.FAT_ZEBRA
                            && appSettings.data.AppSettings.DefaultFatZebraSettings !== null
                            && this.fatZebraPaymentProviderService !== null:
                            return this.fatZebraPaymentProviderService
                                .requestCardToken(locationNo, appSettings.data.AppSettings.DefaultFatZebraSettings)
                                .pipe(
                                    map(fatZebraResponse => ({
                                        token: fatZebraResponse.Verification,
                                        directPostUrl: fatZebraResponse.DirectPostUrl,
                                        returnUrlAfterRedirect: fatZebraResponse.ReturnPath,
                                    }))
                                );

                        default:
                            return throwError('Default payment provider not configured');

                    }
                }),
                catchError(ex => {
                    console.error('Unable to get card token', ex);

                    return throwError(ex);
                })
            );
    }

    public getCardItems(clientAppKey: string = this.config.api.key): Observable<State.IPaginatedListPaymentAccountsListResponse> {
        return this.httpClient
            .get<APIv3.MembersGetMemberCards.Responses.$200>(`${Utils.HTTP.switchApi(this.config.api.base)}/members/my/creditCards`)
            .pipe(
                map(data => ({ ...data, Items: data.Items.filter(item => item.Provider === this.config.paymentProvider) })),
                map((results: APIv3.MembersGetMemberCards.Responses.$200) => CreditCardsMapper.mapGetCardItems(results))
            );
    }

    public addMemberCard(cart: State.ICreatePaymentAccountRequest): Observable<State.ICreatePaymentAccountResponse> {
        const postModel: APIv3.MembersCreateMemberCard.Parameters.Request = CreditCardsMapper.mapAddMemberCardRequest(cart);

        return this.httpClient
            .post<APIv3.MembersCreateMemberCard.Responses.$200>(`${Utils.HTTP.switchApi(this.config.api.base)}/members/my/creditCards`, postModel)
            .pipe(
                map((results: APIv3.MembersCreateMemberCard.Responses.$200) => CreditCardsMapper.mapAddMemberCardResponse(results))
            );
    }

    public removeMemberCardRequest(cardId: number, clientAppKey: string = this.config.api.key): Observable<boolean> {
        return this.httpClient
            .delete<APIv3.MembersRemoveMemberCard.Responses.$200>(`${Utils.HTTP.switchApi(this.config.api.base)}/members/my/creditCards/${cardId}`)
            .pipe(
                map((results: APIv3.MembersRemoveMemberCard.Responses.$200) => CreditCardsMapper.mapRemoveMemberCard(results))
            );
    }

    public async addCreditCardWithRedirect(cardData: OLO.CreditCards.ICreditCardDetails): Promise<APICommon.IPaymentProviderConfigParams> {
        this.store.dispatch(actions.GetCreditCardTokenWithRedirect(cardData));

        return new Promise<APICommon.IPaymentProviderConfigParams>((resolve, reject) => {
            Utils.Redirect.setRedirect()
                .then(() => {
                    this.store
                        .pipe(
                            select(selectors.getCardState),
                            filter(state => state.token.isGettingToken === false && (state.token.hasSucceeded === true || state.token.hasFailed === true)),
                            take(1),
                            switchMap(state => {
                                if (state.token.hasSucceeded && state.activeCardRedirectUrl) {
                                    if (Utils.Redirect.isRedirecting()) {
                                        this.store.dispatch(actions.SelectActiveCreditCardId({ cardId: null }));
                                        this.store.dispatch(actions.SelectActiveCreditCardToken({ token: null }));
                                        this.store.dispatch(actions.StateSave());

                                        return this.actions$
                                            .pipe(
                                                ofType(
                                                    actions.StateSaveSuccess,
                                                    actions.StateSaveError
                                                ),
                                                take(1),
                                                switchMap(action => {
                                                    if (action.type === actions.StateSaveSuccess.type) {
                                                        return of({
                                                            redirectUrl: state.activeCardRedirectUrl,
                                                            verificationToken: state.sessionToken,
                                                            returnUrlAfterRedirect: state.activeCardReturnUrlAfterRedirect,
                                                        });
                                                    }

                                                    return of(null);
                                                })
                                            );
                                    }

                                    return of({
                                        redirectUrl: state.activeCardRedirectUrl,
                                        verificationToken: state.sessionToken,
                                        returnUrlAfterRedirect: state.activeCardReturnUrlAfterRedirect,
                                    });
                                }

                                return of(null);
                            }),
                            delay(10),
                        ).subscribe(obj => {
                            if (obj) {
                                return resolve(obj);
                            }

                            Utils.Redirect.unsetRedirect()
                                .then(() => {
                                    if (this.config.demoMode === true) {
                                        return resolve({ redirectUrl: 'DEMO_URL' });
                                    }
                                    reject('There was an error getting redirectUrl for credit card');
                                });
                        });
                });
        });
    }

    public async handleCardReturnRedirect(
        status: boolean,
        providerResponseParams: APICommon.IPaymentProviderPossibleResponseParams = { token: null }
    ): Promise<boolean> {
        this.store.dispatch(actions.StateRestore({
            setProps: {
                modals: []
            }
        }));

        return new Promise(resolve => {
            Utils.Redirect.unsetRedirect()
                .then(() => {
                    if (!status) {
                        console.warn('Provider responded with "FAILED" status. Please contact payment provider for more details.');
                        this.store.dispatch(actions.CreditCardsValidateErrorRequest({
                            responseParams: {}
                        }));

                        return resolve(false);
                    }

                    this.store.dispatch(actions.CreditCardsValidateRequest({
                        responseParams: providerResponseParams
                    }));

                    this.store
                        .pipe(
                            select(selectors.getCardState),
                            filter(state => (state.validation.hasSucceeded === true || state.validation.hasFailed === true)),
                            take(1)
                        ).subscribe(state => {
                            if (state.validation.hasFailed) {
                                console.warn('Payment method validation failed failed');

                                return resolve(false);
                            }
                            resolve(true);
                        });

                });
        });
    }
}
