import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { DrawCollapseService } from './draw-collapse.shared.service';

@Injectable()
export class DrawCollapseResolver implements Resolve<boolean> {
    constructor(
        public drawCollapseService: DrawCollapseService,
    ) { }

    resolve(): Observable<any> | Promise<boolean> | any {
        return new Promise(resolve => {
            if (this.drawCollapseService.hasInitialized) {
                setTimeout(() => {
                    this.drawCollapseService.tempUnlockScrollOnToggle();
                    this.drawCollapseService.toggleDraw(false);

                    return resolve(true);
                }, this.drawCollapseService.transitionTime);
            } else {
                this.drawCollapseService.tempUnlockScrollOnToggle();
                this.drawCollapseService.toggleDraw(false);

                return resolve(true);
            }
        });
    }
}
