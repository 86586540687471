import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { MenuFlowsEffects } from './menu-flows.effects';
import { menuFlowsReducer } from './menu-flows.reducer';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature('menuFlows', menuFlowsReducer),
        EffectsModule.forFeature([
            MenuFlowsEffects
        ]),
    ],
    exports: [],
    providers: [],
})
export class MenuFlowsStateModule { }
