import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { ProductImagesEffects } from './product-images.effects';
import { productImagesReducer } from './product-images.reducer';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature('productImages', productImagesReducer),
        EffectsModule.forFeature([
            ProductImagesEffects
        ]),
    ],
    exports: [],
    providers: [],
})
export class ProductImagesStateModule { }
