import { Injectable } from '@angular/core';
import { Store, select, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Services from '@shared/core/services';

import * as StateModels from '../interface';

import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, filter, tap } from 'rxjs/operators';
import { Controllers } from '@shared/core';


@Injectable()
export class HistoryOrdersEffects {
    @Effect() public requestSingleHistoryOrder$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.HistoryOrderRequest,
            ),
            switchMap(action => this._onlineOrdersService.getOnlineOrder(action.orderId)
                .pipe(
                    map(payload => actions.HistoryOrderSuccessRequest({ orderId: action.orderId, payload })),
                    catchError(ex => of(actions.HistoryOrderErrorRequest({ orderId: action.orderId, ex }))),
                ))
        );

    @Effect() public onRequestHistoryOrders$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.HistoryOrdersRequest
            ),
            switchMap(action => this._store
                .pipe(
                    select(selectors.getCurrentMember),
                    filter(member => member !== null),
                    take(1),
                    switchMap(member => this._onlineOrdersService.getOnlineOrders({
                        ...action.params
                    }).pipe(
                        map(response => actions.HistoryOrdersSuccessRequest({ params: action.params, payload: response.Items })),
                        tap(response => {
                            const locationIds = [...new Set(response.payload.map(order => order.PickupLocation))];
                            this.controllers.images.requestThumbForLocations(locationIds);
                        }),
                        catchError(ex => of(actions.HistoryOrdersErrorRequest({ params: action.params, ex }))),
                    ))
                )),
        );

    constructor(
        private _actions$: Actions,
        private _onlineOrdersService: Services.OnlineOrdersService,
        private _store: Store<StateModels.IStateShared>,
        private controllers: Controllers
    ) { }
}
