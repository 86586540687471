import { Numbers } from './numbers.utils';
export class Dimensions {
    public static caluclateHeroImageSize(): OLO.Common.Dimensions {
        const width: number = window.innerWidth > window.innerHeight ? window.innerWidth : null;
        const height: number = window.innerWidth > window.innerHeight ? null : window.innerHeight;

        return {
            width: Numbers.calulateNearestLargerMultipleOf(width),
            height: Numbers.calulateNearestLargerMultipleOf(height)
        };
    }

    public static calculateThumbSize(): OLO.Common.Dimensions {
        const width = Math.floor(window.innerWidth + (window.innerWidth * 0.1));
        const height = Math.floor(window.innerHeight + (window.innerHeight * 0.1));

        let thumbSizeRation: number = 3.2;
        if (window.innerWidth > 2000) {
            thumbSizeRation = 4.2;
        }

        if (window.innerWidth < 1000) {
            thumbSizeRation = 2.2;
        }

        if (window.innerWidth < 610) {
            thumbSizeRation = 1.2;
        }

        const calculatedWidth = Math.floor(width / thumbSizeRation);
        const calculatedHeight = Math.floor(height / thumbSizeRation);

        return {
            width: Numbers.calulateNearestLargerMultipleOf(calculatedWidth),
            height: Numbers.calulateNearestLargerMultipleOf(calculatedHeight)
        };
    }

}
