import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { GoogleEffects } from './google.effects';
import { googleReducer } from './google.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('google', googleReducer),
        EffectsModule.forFeature([
            GoogleEffects
        ]),
    ],
})
export class GoogleStateModule { }
