import { Inject, Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import * as State from '@shared/state';
import * as Tokens from '@shared/core/tokens';
import * as Models from '@shared/core/models';

import { Observable } from 'rxjs';
import { map, combineLatest } from 'rxjs/operators';
import * as Services from '@shared/core/services';

@Injectable({
    providedIn: 'root',
})
export class CollectionTypeController {
    constructor(@Inject(Tokens.CONFIG_TOKEN) private _config: IConfig, private _store: Store<State.IStateShared>, private _modalsService: Services.ModalsService) {}

    public getCollection$(): Observable<State.ICollectionType> {
        return this._store.pipe(select(selectors.getCollectionType));
    }

    public collectionTypeGroupId$(): Observable<OLO.Enums.COLLECTION_TYPE> {
        return this._store.pipe(select(selectors.getSelectedCollectionTypeGroupId(this._config)));
    }

    public collectionTypeLabel$(): Observable<string> {
        return this.collectionTypeGroupId$().pipe(
            map((e) => {
                switch (e) {
                    case OLO.Enums.COLLECTION_TYPE.DELIVERY:
                        return 'Delivery';
                    case OLO.Enums.COLLECTION_TYPE.DINE_IN:
                        return 'Dine in';
                    default:
                        return 'Ordering from';
                }
            }),
        );
    }

    public collectionTypeCheckoutLabel$(): Observable<string> {
        return this.collectionTypeGroupId$().pipe(
            map((e) => {
                switch (e) {
                    case OLO.Enums.COLLECTION_TYPE.DELIVERY:
                        return 'Delivery details';
                    case OLO.Enums.COLLECTION_TYPE.DINE_IN:
                        return 'Dine in';
                    default:
                        return 'Pick up type';
                }
            }),
        );
    }

    public isDineInActive$(): Observable<boolean> {
        return this._store.pipe(select(selectors.isCollectionTypeDineIn(this._config)));
    }

    public isDeliveryActive$(): Observable<boolean> {
        return this._store.pipe(select(selectors.isCollectionTypeDelivery(this._config)));
    }

    public showCollectionToggleForHomePage$(): Observable<boolean> {
        return this._store.pipe(select(selectors.showCollectionTypeToggleForHomePage(this._config)));
    }

    public setCollectionType(
        orderTypeId: number = OLO.Enums.COLLECTION_TYPE.PICKUP,
        data: {
            address?: APICommon.IGooglePlaceDetails;
            tableNo?: string;
        } = {},
    ): void {
        this._store.dispatch(
            actions.SetCollectionType(new State.CollectionTypeParams(orderTypeId, data.address || null, data.tableNo || null))
        );
    }

    public collectionTypesLinks$(): Observable<Array<Models.CollectionTypeItem>> {
        return this._store.pipe(select(selectors.getCollectionTypesList(this._config)));
    }

    public collectionTypesLinksForLocation$(locationNo: number): Observable<Array<Models.CollectionTypeItem>> {
        return this._store.pipe(select(selectors.getCollectionTypesListForLocation(locationNo, this._config)));
    }

    public collectionTypesLinksSelected$(): Observable<Models.CollectionTypeItem> {
        return this.collectionTypesLinks$().pipe(map((arr) => arr.find((obj) => obj.IsActive)));
    }

    public collectionTypesLinksSelectedForLocation$(locationNo: number): Observable<Models.CollectionTypeItem> {
        return this.collectionTypesLinksForLocation$(locationNo).pipe(
            combineLatest(this._store.pipe(select(selectors.getCollectionType))),
            map(([arr, collectionType]) => arr.find((obj) => obj.OrderTypeId === collectionType.orderTypeId || obj.GroupType === collectionType.orderTypeId)),
        );
    }

    public patchOrderTypeValue(orderTypeId: number = null): void {
        this._store.dispatch(actions.PatchOrderTypeIdCollectionTypeValue({ orderTypeId }));
    }

    public patchTableNoValue(tableNo: string = null): void {
        this._store.dispatch(actions.PatchTableNoCollectionTypeValue({ tableNo }));
    }

    public patchAddressValue(address: APICommon.IGooglePlaceDetails = null): void {
        console.warn('DISPATCH', address);
        this._store.dispatch(actions.PatchAddressCollectionTypeValue({ address }));
    }
}
