import { createAction, props } from '@ngrx/store';

export const FreeProductImageRequest = createAction(
    '[Free Products Images] Request image',
    props<{ productId: number; }>(),
);

export const FreeProductImageSuccessRequest = createAction(
    '[Free Products Images] Success request image',
    props<{ productId: number, payload: string; }>(),
);

export const FreeProductImageErrorRequest = createAction(
    '[Free Products Images] Error request image',
    props<{ productId: number, ex?: any; }>(),
);
