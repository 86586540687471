import { Injectable, Inject } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Utils from '@shared/core/utils';
import * as Tokens from '@shared/core/tokens';
import * as State from '@shared/state/interface';
import * as Services from '@shared/core/services';

import * as StateModels from '../interface';

import { Observable, of, timer } from 'rxjs';
import { switchMap, auditTime, withLatestFrom, take, audit, filter, tap } from 'rxjs/operators';
import { IMenuFlowDetailsModel } from '@shared/state/interface';

@Injectable()
export class CartEffects {
    @Effect() public onCollectionTypeSetUpdateCart$: Observable<Action> = this._actions$.pipe(
        ofType(actions.SetCollectionType),
        withLatestFrom(
            this._store.pipe(select(selectors.getCart)),
            this._store.pipe(select(selectors.getCurrentLocationNo)),
            this._store.pipe(select(selectors.getSelectedOrderType)),
            this._store.pipe(select(selectors.isCheckoutPage)),
        ),
        filter(([action, cart, currentLocation]) => !!cart?.locationNo === true && currentLocation !== null),
        switchMap(([action, cart, currentLocationNo, currentOrderType, isCheckoutPage]) => {
            const isOnCurrentLocation: boolean = cart.locationNo === currentLocationNo;
            const orderGroupHasChanged: boolean = action.orderTypeId !== null && !Utils.OnlineOrders.orderTypesGroupMatch(currentOrderType?.Id, action.orderTypeId, this._config);

            if(isCheckoutPage && orderGroupHasChanged) {
                return [
                    actions.CartSetupCollectionTypeId({ collectionTypeId: action.orderTypeId })
                ];
            }

            if (isOnCurrentLocation) {
                return [];
            };

            return this._store.pipe(
                select(selectors.getOnlineMenu),
                auditTime(100),
                filter((onlineMenu) => !!onlineMenu?.data !== false && onlineMenu.hasSucceeded === true),
                take(1),
                withLatestFrom(
                    this._store.pipe(select(selectors.getCurrentPickupTime)),
                    this._store.pipe(select(selectors.isCartEmpty))
                ),
                switchMap(([onlineMenu, currentPickupTime, isCartEmpty]) => {
                    if (!isCartEmpty) {
                        return [];
                    }

                    return [
                        actions.CartSetLocationNo(currentLocationNo),
                        actions.CartSetPickupTime({ ...currentPickupTime }),
                        actions.CartSetOnlineMenu({ onlineMenu: onlineMenu.data }),
                        actions.CartRemoveAllItems(),
                    ];
                }),
            );
        }),
    );

    @Effect() public onTransferItemToCartValidateAndSetBasicCartProperties$: Observable<Action> = this._actions$.pipe(
        ofType(
            actions.CartSetup,
            actions.CartSetupWithMultipleItems,
            actions.CartSetupCollectionTypeId,
            actions.CartPickupTimeUpdate
        ),
        withLatestFrom(
            this._store.pipe(select(selectors.getCart)),
            this._store.pipe(select(selectors.getCurrentLocationNo)),
            this._store.pipe(select(selectors.getCurrentPickupTime)),
            this._store.pipe(select(selectors.getOnlineMenuData)),
            this._store.pipe(select(selectors.getMenuFlowDetailsByWizzard)),
            this._store.pipe(select(selectors.getWizzardMenuFlow)),
            this._store.pipe(select(selectors.isCheckoutPage)),
            this._store.pipe(select(selectors.getSelectedOrderType)),
            this._store.pipe(select(selectors.getOrderTypeId)),
        ),
        switchMap(([action, cart, currentLocationNo, currentPickupTime, onlineMenu, menuFlowDetails, wizzardMenuFlow, isCheckoutPage, currentOrderType, nextCollectionType]) => {
            if (action.type === actions.CartPickupTimeUpdate.type) {
                currentPickupTime = action.pickupTime;
            }

            if (isCheckoutPage) {
                onlineMenu = cart.onlineMenu;
            }

            if (action.type === actions.CartSetup.type && action.item?.MenuFlowId) {
                /* Validate menuFlow wizzard errors first */
                const errors: State.IWizzardError[] = this._wizzardService.validateWizzardItems(menuFlowDetails, wizzardMenuFlow);
                if (errors.length > 0) {
                    return of(actions.WizzardValidate({ errors }));
                }
            }

            const isEditing: boolean = action.type === actions.CartSetup.type && action.item && !!action.item['_Id'] === true;

            const summary = {
                cartIsEmpty: cart.itemsSimple.length === 0 && cart.itemsMenuFlow.length === 0,
                isCartLocationNoSet: !!cart.locationNo,
                isCartPickupTimeSet: !!cart.pickupTime,
                isOnlineMenuTimeSet: !!cart.onlineMenu && !!cart.onlineMenu.StartTime && !!cart.onlineMenu.EndTime,
                /* checks for warning */
                isChangingOrderTypeGroup: !Utils.OnlineOrders.orderTypesGroupMatch(currentOrderType?.Id, nextCollectionType, this._config),
                isAddingToCartFromDifferentLocation: isEditing ? false : cart.locationNo !== currentLocationNo,
                isCurrentPickupTimeDifferentThanCartPickupTime: isEditing
                    ? false
                    : !!currentPickupTime === false || cart.pickupTime === null || currentPickupTime.Id !== cart.pickupTime.Id /* unsafe - show warning modal */,
                isCartOnlineMenuTimeDifferentThanCurrent: isEditing
                    ? false
                    : cart.onlineMenu !== null
                        ? cart.onlineMenu.StartTime !== onlineMenu.StartTime || cart.onlineMenu.EndTime !== onlineMenu.EndTime
                        : true,
                /* unsafe - show warning modal - onlineMenu might be null if user is editing item without onlineMenu context */
                /* summary for 3 above */
                showEmptyWarningModal: false,
                forceCartReset: false,
            };

            /* Fix ordering when page reloads when in asap */
            const isOrderingInASAPMode: boolean = currentPickupTime && currentPickupTime.IsAsap === true && (cart.pickupTime === null || cart.pickupTime.IsAsap === true);
            if (isOrderingInASAPMode) {
                /* Check if difference is less then pickupTimeOrderTimeout */
                summary.isCurrentPickupTimeDifferentThanCartPickupTime = false;
            }
            const dispatchActions = (...extraActions: Action[]) => {
                const arr: Action[] = [];
                /* #1 Cart locationNo */
                if (!summary.isCartLocationNoSet || summary.isAddingToCartFromDifferentLocation) {
                    arr.push(actions.CartSetLocationNo(currentLocationNo));
                }
                /* #2 Cart PickupTime */
                if (!summary.isCartPickupTimeSet || summary.isCurrentPickupTimeDifferentThanCartPickupTime) {
                    arr.push(actions.CartSetPickupTime({ ...currentPickupTime }));
                }
                /* #3 OnlineMenu time */
                if (!summary.isOnlineMenuTimeSet || summary.isCartOnlineMenuTimeDifferentThanCurrent) {
                    arr.push(actions.CartSetOnlineMenu({ onlineMenu }));
                }
                /**
                 * #4 Cart's CollectionTypeId - no fucking idea... Konrad's action
                 */
                if(summary.isChangingOrderTypeGroup) {
                    arr.push(actions.CartSetCollectionTypeId({ collectionTypeId: nextCollectionType }));
                }


                if (action.type === actions.CartSetup.type) {
                    arr.push(
                        actions.CartTransferItemRequest({
                            modalId: action.modalId,
                            locationNo: currentLocationNo,
                            item: action.item,
                        }),
                    );
                }

                if (action.type === actions.CartSetupWithMultipleItems.type) {
                    const menuFlows: State.ICartMenuFlow[] = action.menuFlows;
                    const simpleItems: State.ICartSimpleItem[] = action.simpleItems;

                    if (menuFlows && menuFlows.length) {
                        menuFlows.forEach((menuFlow) => {
                            arr.push(
                                actions.CartMenuFlowAddWithMerge({
                                    locationNo: currentLocationNo,
                                    item: {
                                        ...Utils.Items.convertToSanitizedMenuFlowItem(menuFlow),
                                    },
                                }),
                            );
                        });
                    }

                    if (simpleItems && simpleItems.length) {
                        simpleItems.forEach((simpleItem) => {
                            arr.push(
                                actions.CartSimpleItemAdd({
                                    locationNo: currentLocationNo,
                                    item: {
                                        ...Utils.Items.convertToSanitizedSimpleItem(simpleItem),
                                    },
                                }),
                            );
                        });
                    }
                }

                if (summary.forceCartReset) {
                    arr.unshift(actions.CartRemoveAllItems());
                }

                return extraActions ? arr.concat(extraActions) : arr;
            };
            if (!summary.cartIsEmpty) {
                summary.forceCartReset = summary.isAddingToCartFromDifferentLocation
                    || summary.isCurrentPickupTimeDifferentThanCartPickupTime
                    || summary.isCartOnlineMenuTimeDifferentThanCurrent
                    || summary.isChangingOrderTypeGroup && isCheckoutPage;
            }

            summary.showEmptyWarningModal = summary.forceCartReset === true && summary.cartIsEmpty === false;

            if (!summary.showEmptyWarningModal) {
                if (action.type === actions.CartSetupWithMultipleItems.type) {
                    this._modalsService.close(action.modalId);
                }

                return dispatchActions();
            }
            /* Handle modal */
            const modalParams: State.IModal = {
                type: 'active-order-prompt',
                animate: null,
            };
            if (action.modalId) {
                this._modalsService.swap(action.modalId, modalParams);
            } else {
                this._modalsService.show(modalParams);
            }

            return this._actions$.pipe(
                ofType(
                    actions.CartActiveOrderContinue,
                    actions.CartActiveOrderStartNew
                ),
                take(1),
                switchMap((activeOrderDecision) => {
                    this._modalsService.close(action.modalId);
                    if (activeOrderDecision.type === actions.CartActiveOrderContinue.type) {
                        /**
                         * Values in SetCollectionType might need to be adjusted in the future or in some special cases I don't remember righ now
                         */

                        return [
                            actions.PatchOrderTypeIdCollectionTypeValue({
                                orderTypeId: currentOrderType?.Id || null,
                            }),
                            actions.CurrentLocationPickupTimeSet({ ...cart.pickupTime })
                        ];
                    }

                    if (activeOrderDecision.type === actions.CartActiveOrderStartNew.type) {
                        return dispatchActions(actions.CartSetPickupTime({ ...currentPickupTime }) /* AOLO-384 - fix */);
                    }
                }),
            );
        }),
    );

    @Effect() public addItemToCartAndCheckForAnyUpsells$: Observable<Action> = this._actions$.pipe(
        ofType(actions.CartTransferItemRequest),
        switchMap((action) =>
            this._store.pipe(
                select(selectors.getModalById(action.modalId)),
                take(1),
                withLatestFrom(this._store.select(selectors.getMenuFlowDetailsByWizzard), this._store.select(selectors.getWizzardMenuFlow)),
                switchMap(([modal, menuFlow, fromWizzardMenuFlow]) => {
                    const isMenuFlow: boolean = !!action.item.MenuFlowId;
                    if (!isMenuFlow) {
                        const isEditing: boolean = Number.isInteger(action.item._Id);

                        this._modalsService.close(action.modalId);

                        if (!isEditing) {
                            const productWithId = {
                                ...action.item,
                            } as State.ICartSimpleItem;
                            productWithId._Id = new Date().getTime();

                            return [
                                actions.CartSimpleItemAdd({ locationNo: action.locationNo, item: Utils.Items.convertToSanitizedSimpleItem(productWithId) }),
                                actions.WizzardUnmountAll(),
                            ];
                        }

                        return [
                            actions.CartSimpleItemUpdate({ item: Utils.Items.convertToSanitizedSimpleItem(action.item as State.ICartSimpleItem) }),
                            actions.WizzardUnmountAll(),
                        ];
                    }
                    const bundleActions: Action[] = [];
                    const wizzardMenuFlow: State.IWizzardMenuFlow = fromWizzardMenuFlow as State.IWizzardMenuFlow;
                    const menuFlowDetails: IMenuFlowDetailsModel = menuFlow as IMenuFlowDetailsModel;
                    const isNew: boolean = wizzardMenuFlow._Id === null;

                    const errors: State.IWizzardError[] = this._wizzardService.validateWizzardItems(menuFlow, wizzardMenuFlow);
                    if (errors.length > 0) {
                        return of(actions.WizzardValidate({ errors }));
                    }

                    if (isNew) {
                        wizzardMenuFlow._Id = new Date().getTime();

                        bundleActions.push(
                            actions.CartMenuFlowAddWithMerge({
                                locationNo: wizzardMenuFlow.LocationNo,
                                item: {
                                    _Id: new Date().getTime(),
                                    ...wizzardMenuFlow,
                                },
                            }),
                        );
                    } else {
                        bundleActions.push(actions.CartMenuFlowUpdate({ item: wizzardMenuFlow }));
                    }

                    bundleActions.push(actions.WizzardUnmountAll());

                    const showUpsellModal: boolean = isNew && Number.isInteger((menuFlowDetails as IMenuFlowDetailsModel).UpsellMenuFlowId);
                    if (showUpsellModal) {
                        bundleActions.push(
                            actions.WizzardMenuFlowUpsellSetupRequest((menuFlowDetails as IMenuFlowDetailsModel).UpsellMenuFlowId, wizzardMenuFlow.LocationNo, action.modalId),
                        );
                    }

                    /* And hide modal if there is no upsell to show */
                    if (action.modalId && !showUpsellModal) {
                        this._modalsService.close(action.modalId);
                    }

                    return bundleActions;
                }),
            ),
        ),
    );

    @Effect() public findDuplicatedMenuFlowAndMerge$: Observable<Action> = this._actions$ /* AOLO-282 */
        .pipe(
            ofType(actions.CartMenuFlowAddWithMerge),
            withLatestFrom(this._store.select(selectors.getCartMenuFlows)),
            switchMap(([action, cartMenuFlows]) => {
                const newItem: State.ICartMenuFlowExtended = action.item;
                const foundSimilarInCart: State.ICartMenuFlow = cartMenuFlows.find(
                    (obj) =>
                        obj.MenuFlowId === newItem.MenuFlowId &&
                        obj.PosDescription === newItem.PosDescription &&
                        obj.PosDisplay === newItem.PosDisplay &&
                        obj.SpecialInstructions === newItem.SpecialInstructions &&
                        obj.UnitPrice === newItem.UnitPrice,
                );

                return of(foundSimilarInCart).pipe(
                    take(1),
                    switchMap(() => {
                        if (!foundSimilarInCart) return of(false);
                        let isSimilar: boolean = true;

                        if (foundSimilarInCart.Pages.length !== newItem.Pages.length) {
                            return of((isSimilar = false));
                        }
                        foundSimilarInCart.Pages.forEach((Page) => {
                            /* Check if all pages have same PageIdentifier and products QTY prop */
                            const foundPage = newItem.Pages.find((obj) => obj.PageIdentifier === Page.PageIdentifier);
                            if (!foundPage || foundPage.Products.length !== Page.Products.length) {
                                return (isSimilar = false);
                            }

                            /* Check products */
                            Page.Products.forEach((Product) => {
                                const foundProduct = foundPage.Products.find(
                                    (obj) =>
                                        obj.ProductId === Product.ProductId &&
                                        obj.Quantity === Product.Quantity &&
                                        obj.OriginalPrice === Product.OriginalPrice &&
                                        obj.OverridedPrice === Product.OverridedPrice &&
                                        obj.PageProductIdentifier === Product.PageProductIdentifier &&
                                        obj.Plu === Product.Plu &&
                                        obj.ProductName === Product.ProductName &&
                                        obj.ProductDescription === Product.ProductDescription,
                                );

                                if (!foundProduct) {
                                    return (isSimilar = false);
                                }

                                /* Check IngredientsAdded */
                                const foundIngredients = foundProduct.IngredientsChanges ? foundProduct.IngredientsChanges : null;
                                const productIngredients = Product.IngredientsChanges ? Product.IngredientsChanges : null;
                                if (foundIngredients === null && productIngredients === null) {
                                    return;
                                }
                                if (typeof foundIngredients !== typeof productIngredients) {
                                    return (isSimilar = false);
                                }

                                if (foundProduct.IngredientsChanges.IngredientsAdded.length !== Product.IngredientsChanges.IngredientsAdded.length) {
                                    return (isSimilar = false);
                                }
                                Product.IngredientsChanges.IngredientsAdded.forEach((ingredient) => {
                                    const foundIngredient = foundProduct.IngredientsChanges.IngredientsAdded.find(
                                        (obj) =>
                                            obj.ExtraPrice === ingredient.ExtraPrice &&
                                            obj.IngredientPLU === ingredient.IngredientPLU &&
                                            obj.ModifierID === ingredient.ModifierID &&
                                            obj.IngredientPLU === ingredient.IngredientPLU,
                                    );
                                    if (!foundIngredient) {
                                        return (isSimilar = false);
                                    }
                                });

                                /* Check IngredientsModified */
                                if (foundProduct.IngredientsChanges.IngredientsModified.length !== Product.IngredientsChanges.IngredientsModified.length) {
                                    return (isSimilar = false);
                                }
                                Product.IngredientsChanges.IngredientsModified.forEach((ingredient) => {
                                    const foundIngredient = foundProduct.IngredientsChanges.IngredientsModified.find(
                                        (obj) =>
                                            obj.ExtraPrice === ingredient.ExtraPrice &&
                                            obj.IngredientPLU === ingredient.IngredientPLU &&
                                            obj.ModifierID === ingredient.ModifierID &&
                                            obj.IngredientPLU === ingredient.IngredientPLU,
                                    );
                                    if (!foundIngredient) {
                                        return (isSimilar = false);
                                    }
                                });

                                /* Check IngredientsRemoved */
                                if (foundProduct.IngredientsChanges.IngredientsRemoved.length !== Product.IngredientsChanges.IngredientsRemoved.length) {
                                    return (isSimilar = false);
                                }
                                Product.IngredientsChanges.IngredientsRemoved.forEach((ingredient) => {
                                    const foundIngredient = foundProduct.IngredientsChanges.IngredientsRemoved.find(
                                        (obj) => obj.IngredientPLU === ingredient.IngredientPLU && obj.ID === ingredient.ID && obj.IngredientPLU === ingredient.IngredientPLU,
                                    );
                                    if (!foundIngredient) {
                                        return (isSimilar = false);
                                    }
                                });

                                /* Check IngredientsRemoved */
                                if (foundProduct.IngredientsChanges.IngredientsSwapped.length !== Product.IngredientsChanges.IngredientsSwapped.length) {
                                    return (isSimilar = false);
                                }
                                Product.IngredientsChanges.IngredientsSwapped.forEach((ingredient) => {
                                    const foundIngredient = foundProduct.IngredientsChanges.IngredientsSwapped.find(
                                        (obj) =>
                                            obj.ExtraPrice === ingredient.ExtraPrice &&
                                            obj.ID === ingredient.ID &&
                                            obj.ModifierID === ingredient.ModifierID &&
                                            obj.ModifierName === ingredient.ModifierName &&
                                            obj.NewIngredientPLU === ingredient.NewIngredientPLU &&
                                            obj.OldIngredientPLU === ingredient.OldIngredientPLU &&
                                            obj.OrderItemId === obj.OrderItemId,
                                    );
                                    if (!foundIngredient) {
                                        return (isSimilar = false);
                                    }
                                });
                            });
                        });

                        return of(isSimilar);
                    }),
                    switchMap((isSimilar) => {
                        if (!isSimilar) {
                            const markedHidden = {
                                ...newItem,
                                Pages: newItem.Pages.map((page) => {
                                    if (page.HideFromKiosk === true) {
                                        return {
                                            ...page,
                                            Products: page.Products.map((product) => ({
                                                ...product,
                                                _HideFromKiosk: true,
                                            })),
                                        };
                                    }

                                    return page;
                                }),
                            };

                            return of(actions.CartMenuFlowAdd({ locationNo: action.locationNo, item: markedHidden }));
                        }

                        return this._store.pipe(
                            select(selectors.isDeviceMobile),
                            take(1),
                            switchMap((isMobile) => {
                                if (!isMobile) {
                                    setTimeout(() => {
                                        this._cartService.showPopup();
                                    }, 100);
                                }

                                return of(actions.CartMenuFlowIncrement(foundSimilarInCart._Id, newItem.Quantity));
                            }),
                        );
                    }),
                );
            }),
        );

    @Effect() public closeActiveOrderPromptWhenUsingReorder$: Observable<Action> = this._actions$.pipe(
        ofType(
            actions.CartSimpleItemAdd,
            actions.CartMenuFlowAdd
        ),
        audit(() => timer(100)),
        withLatestFrom(
            this._store.pipe(select(selectors.getAllModals)),
            this._store.pipe(select(selectors.isDeviceMobile)),
            this._store.pipe(select(selectors.isCartEmpty)),
            (action, modals, isMobile, isCartEmpty) => {
                //
                //  Reorder case with Active order prompt
                //  Reordering items ommit upsell checks, where modals are controlled, so they won't get closed.
                //  I know this is a bit robust, but when doing things other way around - starting project from the middle, this is
                //  easier than refactoring complicated flow.
                //  This should be handled better, but for now, let's just pretend it works
                //
                let modal: State.IModal;
                if (action.item._IsReorder) {
                    /* We've got item from reorder here - should close modal now */
                    modal = modals.find((obj) => obj.type === 'active-order-prompt');
                }

                return [action, modal, isMobile, isCartEmpty];
            },
        ),
        switchMap(([action, modal, isMobile, isCartEmpty]) => {
            if (modal) {
                this._modalsService.close((modal as State.IModal).id);
            }

            if (!isMobile && !isCartEmpty) {
                this._cartService.showPopup();
            }

            return [];
        }),
    );

    @Effect() public setupWizzardOnCartItemEdit$: Observable<Action> = this._actions$.pipe(
        ofType(actions.CartEditItem),
        withLatestFrom(this._store.pipe(select(selectors.getCart)), (action, cart: State.ICart) => [action.item, cart.locationNo]),
        switchMap(([item, locationNo]) => of(actions.WizzardSetupItem(locationNo as number, item as State.ICartMenuFlow | State.ICartSimpleItem))),
    );

    @Effect() public setupWizzardOnCartAddVoucher$: Observable<Action> = this._actions$.pipe(
        ofType(actions.CartAddVoucher),
        tap((action) => this._store.dispatch(actions.OnlineOrderClearVoucherRequest())),
        withLatestFrom(this._store.pipe(select(selectors.getCart)), (action, cart: State.ICart) => [action, cart.locationNo]),
        switchMap(([action, locationNo]) => of(actions.WizzardAddVoucher(locationNo as number))),
    );

    @Effect({ dispatch: false }) public saveCartContentsInStorage$: Observable<any> = this._actions$.pipe(
        ofType(
            actions.CartReset,
            actions.CartRemoveAllItems,
            actions.CartMenuFlowAdd,
            actions.CartMenuFlowDecrement,
            actions.CartMenuFlowIncrement,
            actions.CartMenuFlowRemove,
            actions.CartMenuFlowUpdate,
            actions.CartSimpleItemAdd,
            actions.CartSimpleItemDecrement,
            actions.CartSimpleItemIncrement,
            actions.CartSimpleItemRemove,
            actions.CartSimpleItemUpdate,
            actions.CartAddActivatedVoucher,
            actions.CartRemoveActivatedVoucher,
        ),
        auditTime(500),
        withLatestFrom(this._store.select(selectors.getCart)),
        switchMap(([action, cart]) => {
            if (action.type === actions.CartReset.type) {
                Utils.Storage.remove(OLO.Enums.CART_STORAGE.DATA);
            } else {
                Utils.Storage.set(OLO.Enums.CART_STORAGE.DATA, this._cryptoService.encrypt(JSON.stringify(cart)));
            }

            return [];
        }),
    );

    @Effect({ dispatch: false }) public removeCartDataFromStorage$: Observable<any> = this._actions$.pipe(
        ofType(actions.CartReset),
        switchMap(() => {
            Utils.Storage.remove((OLO.Enums.CART_STORAGE.DATA as unknown) as string);

            return [];
        }),
    );

    @Effect() public recalculateCartContents$: Observable<Action> = this._actions$.pipe(
        ofType(
            actions.CartRemoveAllItems,
            actions.CartMenuFlowAdd,
            actions.CartMenuFlowDecrement,
            actions.CartMenuFlowIncrement,
            actions.CartMenuFlowRemove,
            actions.CartMenuFlowUpdate,
            actions.CartSimpleItemAdd,
            actions.CartSimpleItemDecrement,
            actions.CartSimpleItemIncrement,
            actions.CartSimpleItemRemove,
            actions.CartSimpleItemUpdate,
        ),
        withLatestFrom(this._store.pipe(select(selectors.isCheckoutPage))),
        switchMap(([data, isCheckout]) => {
            if (isCheckout) return of(actions.OnlineOrderRecalculateRequest());

            return [];
        }),
    );

    @Effect() public resetCartOnLastItemRemoved$: Observable<Action> = this._actions$.pipe(
        ofType(actions.CartMenuFlowRemove, actions.CartSimpleItemRemove),
        withLatestFrom(this._store.pipe(select(selectors.getCart))),
        switchMap(([action, cart]) => {
            const productsTotal: number = cart.itemsSimple ? cart.itemsSimple.length : 0;
            const menuFlowsTotal: number = cart.itemsMenuFlow ? cart.itemsMenuFlow.length : 0;

            if (productsTotal || menuFlowsTotal) return [];

            return of(actions.CartReset());
        }),
    );

    @Effect() public resetVoucherOnCartRemoveAllItems$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CartRemoveAllItems
            ),
            withLatestFrom(
                this._store.select(selectors.getActiveVoucher)
            ),
            switchMap(([action, voucher]) => {
                if (voucher) {
                    return of(actions.OnlineOrderRemoveVoucherRequest());
                } else {
                    return [];
                }
            })
        );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _actions$: Actions,
        private _store: Store<StateModels.IStateShared>,
        private _cryptoService: Services.CryptoService,
        private _modalsService: Services.ModalsService,
        private _wizzardService: Services.WizzardService,
        private _cartService: Services.CartService,
    ) {}
}
