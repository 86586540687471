import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, IHistoryOrderState } from '../interface';
import * as Utils from '@shared/core/utils';

const orderSortFunc = (a, b) => {
    switch (true) {
        case a.OrderId > b.OrderId:
            return -1;
        case a.OrderId < b.OrderId:
            return 1;
        default:
            return 0;
    }
};

const state = createFeatureSelector<IStateShared, IHistoryOrderState>('historyOrders');

export const getHistoryOrdersState = createSelector(
    state,
    historyOrders => historyOrders
);

export const getHistoryOrdersStatus = createSelector(
    state,
    historyOrders => ({
        isDownloading: historyOrders?.isDownloading,
        hasSucceeded: historyOrders?.hasSucceeded,
        hasFailed: historyOrders?.hasFailed,
    })
);

export const getHistoryOrders = createSelector(
    state,
    historyOrders => historyOrders.orders
        .filter(order => order.data !== null && Utils.OnlineOrders.isOrderFinalized(order.data.Status) === true)
        .sort(orderSortFunc)
);

export const getHistoryActiveOrders = createSelector(
    state,
    historyOrders => historyOrders.orders
        .filter(order => !!order.data && Utils.OnlineOrders.isOrderActive(order.data.Status) === true)
        .sort(orderSortFunc)
);

export const getHistoryOrder = (orderId: number) => createSelector(
    state,
    historyOrders => historyOrders.orders
        .find(order => !!order.data && order.OrderId === orderId)
);

export const getHistoryOrderStatus = (orderId: number) => createSelector(
    getHistoryOrder(orderId),
    order => order?.data?.Status
);

export const isHistoryOrderPlaced = (orderId: number) => createSelector(
    getHistoryOrderStatus(orderId),
    status => status <= OLO.Enums.ONLINE_ORDER_STATUS.VALIDATED
);

export const isHistoryOrderPreparing = (orderId: number) => createSelector(
    getHistoryOrderStatus(orderId),
    status => status === OLO.Enums.ONLINE_ORDER_STATUS.SENT_TO_KITCHEN
);

export const isHistoryOrderActive = (orderId: number) => createSelector(
    getHistoryOrderStatus(orderId),
    status => status === OLO.Enums.ONLINE_ORDER_STATUS.SENT_TO_SITE || status === OLO.Enums.ONLINE_ORDER_STATUS.RECIVED_AT_SITE
);

export const isHistoryOrderReady = (orderId: number) => createSelector(
    getHistoryOrderStatus(orderId),
    status => status === OLO.Enums.ONLINE_ORDER_STATUS.FINALIZED
);

export const getHistoryOrderTypeGroup = (orderId: number, config: IConfig) => createSelector(
    getHistoryOrder(orderId),
    order => {
        if (!order || !order.data) return null;

        return Utils.OnlineOrders.detectOrderCollectionTypeGroup(order.data, config);
    }
);

export const isHistoryOrderDineIn = (orderId: number, config: IConfig) => createSelector(
    getHistoryOrderTypeGroup(orderId, config),
    type => type === OLO.Enums.COLLECTION_TYPE.DINE_IN,
);

export const isHistoryOrderDineInBuzzer = (orderId: number, config: IConfig) => createSelector(
    getHistoryOrder(orderId),
    order => {
        if (!order || !order.data) return null;
        const orderTypeId: number = order.data.OrderTypeId;

        return orderTypeId === config.collectionTypes?.dineIn?.dineInBuzzer?.orderTypeId;
    }
);

export const getHistoryOrdersByLocationNo = (locationNo: number) => createSelector(
    state,
    historyOrders => {
        if (!historyOrders.orders || historyOrders.orders.length === 0) return [];

        return historyOrders.orders
            .filter(order => order.data !== null && order.data.PickupLocation === locationNo)
            .sort(orderSortFunc);
    }
);

export const isLoadingAnyHistoryOrder = createSelector(
    state,
    historyOrders => {
        const isLoadingAll: boolean = historyOrders.isDownloading === true;
        if (isLoadingAll) return true;

        if (historyOrders.orders) {
            return historyOrders.orders.some(obj => obj.isDownloading === true);
        }

        return false;
    }
);

export const hasDownloadedHistoryOrders = createSelector(
    state,
    historyOrders => historyOrders.downloadedDate !== null && !historyOrders.orders.some(order => order.downloadedDate === null)
);

export const isRequestingHistoryOrderFirstTime = createSelector(
    state,
    hasDownloadedHistoryOrders,
    isLoadingAnyHistoryOrder,
    (s, hasDownloaded, isLoading) => {
        if (hasDownloaded) return false;

        return isLoading === true;
    }
);

export const hasAnyHistoryOrder = createSelector(
    state,
    historyOrders => historyOrders.orders.length > 0 && historyOrders.downloadedDate !== null
);

export const historyOrderIsActive = (orderId: number) => createSelector(
    getHistoryOrder(orderId),
    order => {
        if (!order || !order.data) return null;

        return Utils.OnlineOrders.isOrderActive(order.data.Status);
    }
);

export const historyOrderIsFinalized = (orderId: number) => createSelector(
    getHistoryOrder(orderId),
    order => {
        if (!order || !order.data) return false;

        return order.data.Status === OLO.Enums.ONLINE_ORDER_STATUS.FINALIZED;
    }
);

export const canShowHistoryOrders = (active: boolean = false, ...locationNos: number[]) => createSelector(
    state,
    hasDownloadedHistoryOrders,
    (stateHistory, hasDownloaded) => {
        if (hasDownloaded === false) return true;

        return hasDownloaded && stateHistory.orders.some(order => {
            if (order && order.data) {
                const foundLocation = locationNos.includes(order.data.PickupLocation);

                return Utils.OnlineOrders.isOrderActive(order.data.Status) === active && foundLocation;
            }

            return false;
        });
    }
);

export const getHistoryOrderVoucher = (orderId: number) => createSelector(
    getHistoryOrder(orderId),
    order => order?.data?.ActivatedVouchers?.length ? order.data?.ActivatedVouchers[0] : null
);
