import { Injectable, Inject, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';

import * as Decorators from '@shared/core/decorators';
import * as Tokens from '@shared/core/tokens';
import * as State from '@shared/state/interface';
import * as Utils from '@shared/core/utils';

import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { ImagesMapper } from '@shared/core/mappers/images.shared.mapper';
import { IInteractImageUrlModel } from '@shared/state/interface';

@Injectable({
    providedIn: 'root',
})
export class ImagesService {

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public httpClient: HttpClient,
        public store: Store<State.IStateShared>,
        public rendererFactory2: RendererFactory2,
    ) {
    }

    public preloadImageInMemory(imgUrl: string): Observable<boolean> {
        return Observable.create(observer => {
            if (!imgUrl || imgUrl === 'null') {
                observer.next(false);
                observer.complete();

                return;
            }
            let renderer: Renderer2 = this.rendererFactory2.createRenderer(null, null);

            const holder = renderer.createElement('div');
            const imgElem = renderer.createElement('img');
            renderer.setAttribute(imgElem, 'src', imgUrl);
            renderer.appendChild(holder, imgElem);
            imgElem.onload = () => {
                observer.next(true);
                observer.complete();
            };

            imgElem.onerror = () => {
                observer.next(false);
                observer.complete();
            };

            return () => {
                setTimeout(() => {
                    renderer.destroy();
                    renderer = null;
                }, 1000);
            };

        });
    }

    public getImageForOrderType(params: APICommon.IImagesGetParams, ...orderTypes: number[]): Observable<APICommon.IImageUrlModel[]> {
        const stringParams: string = Utils.HTTP.idsToStringParams('orderTypes', orderTypes);
        const sizeParams: string = `${params.width ? `&width=${params.width}` : ''}${params.height ? `&height=${params.height}` : ''}`;

        return this.httpClient
            .get<APIv3.ImageGetOrderTypeImages.Responses.$200>(`${this.config.api.base}/images/orderType?${stringParams}${sizeParams}`)
            .pipe(
                map((results: APIv3.ImageGetOrderTypeImages.Responses.$200) => ImagesMapper.mapGetImageForOrderType(results))
            );
    }

    public getImageForLocation(locationNo: number, width: number = null, height: number = null): Observable<string> {
        const params: HttpParams = new HttpParams({
            fromObject: ({ width, height } as any)
        });

        return this.httpClient
            .get<APIv3.ImageGetLocationImage.Responses.$200>(`${this.config.api.base}/images/location/${locationNo}`, { params })
            .pipe(
                map((results: APIv3.ImageGetLocationImage.Responses.$200) => ImagesMapper.mapGetImageForLocation(results))
            );
    }

    @Decorators.deprecate()
    public getImagesForLocations(locationNos: number[], width: number = null, height: number = null): Observable<{ locationNo: number; imageUrl: string; }[]> {
        return forkJoin(
            locationNos.map(locationNo =>
                this.getImageForLocation(locationNo, width, height)
                    .pipe(
                        map(imageUrl => ({ locationNo, imageUrl })
                        )
                    )),
        );
    }

    public getImagesForLocationsByType(params: APICommon.ICommonImagesGetParams, ...locationIds: number[]): Observable<APICommon.IImageUrlModel[]> {
        const stringParams: string = Utils.HTTP.idsToStringParams('locationIds', locationIds);
        const sizeParams: string = `${params.width ? `&width=${params.width}` : ''}${params.height ? `&height=${params.height}` : ''}`;

        return this.httpClient
            .get<APIv3.ImageGetLocationsImages.Responses.$200>(`${this.config.api.base}/images/location?${stringParams}&imageType=${params.imageType || 4}${sizeParams}`)
            .pipe(
                map((results: APIv3.ImageGetLocationsImages.Responses.$200) => ImagesMapper.mapGetImagesForLocationsByType(results))
            );
    }

    public getProductImages(params: APICommon.IImagesGetParams, ...productIds: number[]): Observable<APICommon.IImageUrlModel[]> {
        const stringParams: string = Utils.HTTP.idsToStringParams('productIds', productIds);

        return this.httpClient
            .get<APIv3.ImageGetProductImages.Responses.$200>(
            `${this.config.api.base}/images/Products?${stringParams}&width=${params.width || 150}&height=${params.height || 150}`)
            .pipe(
                map((results: APIv3.ImageGetProductImages.Responses.$200) => ImagesMapper.mapGetProductImages(results))
            );
    }

    public getImagesForOnlineMenuPages(params: APICommon.IImagesGetParams, imageType: OLO.Enums.IMAGE_TYPE, pageIds: number[]): Observable<APICommon.IImageUrlModel[]> {
        const stringParams: string = Utils.HTTP.idsToStringParams('pageId', pageIds);

        return this.httpClient
            .get<APIv3.ImageGetOnlineMenuPageImages.Responses.$200>(
            `${this.config.api.base}/images/onlineMenuPage${Utils.HTTP.object2string(params)}&${stringParams}&imageType=${imageType}`)
            .pipe(
                map((results: APIv3.ImageGetOnlineMenuPageImages.Responses.$200) => ImagesMapper.mapGetImagesForOnlineMenuPages(results))
            );
    }

    public getImagesForOnlineMenuPageProducts(params: APICommon.IImagesGetParams, pageId: number): Observable<APICommon.IImageUrlModel[]> {
        return this.httpClient
            .get<APIv3.ImageGetOnlineMenuPageProductsImages.Responses.$200>(`${this.config.api.base}/images/onlineMenuPageProducts/${pageId}`)
            .pipe(
                map((results: APIv3.ImageGetOnlineMenuPageProductsImages.Responses.$200) => ImagesMapper.mapGetImagesForOnlineMenuPageProducts(results))
            );
    }

    public getDietaryTagsImages(params: APICommon.IImagesGetParams, ...dietaryTagIds: number[]): Observable<APICommon.IImageUrlModel[]> {
        const stringParams: string = Utils.HTTP.idsToStringParams('dietaryTagId', dietaryTagIds);

        return this.httpClient
            .get<APIv3.ImageGetDietaryTagsImages.Responses.$200>(
            `${this.config.api.base}/images/dietaryTags?${stringParams}&width=${params.width || 150}&height=${params.height || 150}`)
            .pipe(
                map((results: APIv3.ImageGetDietaryTagsImages.Responses.$200) => ImagesMapper.mapGetDietaryTagsImages(results))
            );

    }

    public getInteracts(params: APICommon.IInteractImagesGetParams, ...productIds: number[]): Observable<IInteractImageUrlModel[]> {
        const stringParams: string = Utils.HTTP.idsToStringParams('productId', productIds);

        return this.httpClient
            .get<APIv3.ImageGetInteractImages.Responses.$200>(`${this.config.api.base}/images/interact${Utils.HTTP.object2string(params)}&${stringParams}`)
            .pipe(
                map((results: APIv3.ImageGetInteractImages.Responses.$200) => ImagesMapper.mapGetInteracts(results))
            );

    }

    public getMenuFlowImages(params: APICommon.IImagesGetParams, ...menuFlowIds: number[]): Observable<APICommon.IImageUrlModel[]> {
        const stringParams: string = Utils.HTTP.idsToStringParams('menuFlowId', menuFlowIds);

        return this.httpClient
            .get<APIv3.ImageGetMenuFlowsImages.Responses.$200>(
            `${this.config.api.base}/images/menuFlows?${stringParams}&width=${params.width || 150}&height=${params.height || 150}`)
            .pipe(
                map((results: APIv3.ImageGetMenuFlowsImages.Responses.$200) => ImagesMapper.mapGetMenuFlowImages(results))
            );
    }

    public getVenueImagesByType(params: APICommon.ICommonImagesGetParams, ...venueIds: number[]): Observable<APICommon.IImageUrlModel[]> {
        const stringParams: string = Utils.HTTP.idsToStringParams('venueIds', venueIds);

        return this.httpClient
            .get<APIv3.ImageGetVenuesImages.Responses.$200>(
            `${this.config.api.base}/images/venue?${stringParams}&imageType=${params.imageType || 4}&width=${params.width || 150}&height=${params.height || 150}`)
            .pipe(
                map((results: APIv3.ImageGetVenuesImages.Responses.$200) => ImagesMapper.mapVenueImagesByType(results))
            );
    }

    public getImageForLoyaltyIntroductionPage(pageId: number, params: APICommon.IImagesGetParams = {}): Observable<string> {
        return this.httpClient
            .get<APIv3.ImageGetIntroductionPageImages.Responses.$200>(
            `${this.config.api.base}/images/introductionPage/${pageId}?width=${params.width || 300}&height=${params.height || 300}`)
            .pipe(
                map((results: APIv3.ImageGetIntroductionPageImages.Responses.$200) => ImagesMapper.mapGetImageForLoyaltyIntroductionPage(results))
            );
    }

    public getImageForMemberFreeProduct(productId: number, params: APICommon.ICommonImagesGetParams = {}): Observable<string> {
        return this.httpClient
            .get<APIv3.ImageGetMemberFreeProductImage.Responses.$200>(
            `${this.config.api.base}/images/memberFreeProducts/${productId}?width=${params.width || 300}&height=${params.height || 300
            }&imageType=${params.imageType || 4}`)
            .pipe(
                map((results: APIv3.ImageGetMemberFreeProductImage.Responses.$200) => ImagesMapper.mapGetImageForMemberFreeProduct(results))
            );
    }

    public getMemberCardBarcode(memberId: number, params: APICommon.IImagesGetParams = {}, type: OLO.Enums.BARCODE_SYMBOLOGY = OLO.Enums.BARCODE_SYMBOLOGY.CODE_QR):
    Observable<string> {
        return this.httpClient
            .get<APIv3.ImageGetMemberCardBarcodeUrl.Responses.$200>(
            `${this.config.api.base}/images/memberCardBarcodes/${memberId}?barcodeSymbology=${type}&width=${params.width || window.innerWidth
            }&height=${params.height || window.innerWidth}`)
            .pipe(
                map((results: APIv3.ImageGetMemberCardBarcodeUrl.Responses.$200) => ImagesMapper.mapGetMemberCardBarcode(results))
            );
    }
}
