import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, IOnlineOrder } from '../interface';
import * as Utils from '@shared/core/utils';
import * as fromOrderTypes from '../orderTypes/order-types.selectors';

const state = createFeatureSelector<IStateShared, IOnlineOrder>('onlineOrder');

export const getOnlineOrder = createSelector(
    state,
    onlineOrder => onlineOrder.data
);

export const getOnlineOrderState = createSelector(
    state,
    onlineOrder => onlineOrder
);

export const getOnlineOrderCreateRequestState = createSelector(
    state,
    onlineOrder => onlineOrder.createRequest
);

export const getOnlineOrderTotalTax = createSelector(
    state,
    onlineOrder => {
        if (
            onlineOrder.recalculateRequest.data !== null
            && onlineOrder.recalculateRequest.data.TotalTaxes !== null
            && onlineOrder.recalculateRequest.data.TotalTaxes.length !== 0
        ) {
            return onlineOrder.data.TotalTaxes
                .map(tax => tax.Value)
                .reduce((sum, current) => sum + current);
        }

        return 0;
    }
);

export const getOnlineOrderRecalcData = createSelector(
    state,
    onlineOrder => onlineOrder.recalculateRequest
);

export const isOnlineOrderLoading = createSelector(
    state,
    onlineOrder => (
        onlineOrder.updateRequest.isUpdating ||
        onlineOrder.downloadRequest.isDownloading ||
        onlineOrder.createRequest.isCreating
    )
);

// export const isOnlineOrderPendingResetSet = createSelector(
//     state,
//     onlineOrder => onlineOrder.pendingStateReset
// );

export const isOnlineOrderRecalculating = createSelector(
    state,
    onlineOrder => onlineOrder.recalculateRequest.isRecalculating || onlineOrder.voucherRequest.isVoucherAdding
);

export const postOnlineOrderFailed = createSelector(
    state,
    onlineOrder => onlineOrder.createRequest.hasFailed
);

export const hasRecalculateFailed = createSelector(
    state,
    onlineOrder => onlineOrder.recalculateRequest.hasFailed
);

export const hasRecalculateFailedErrorMapped = createSelector(
    hasRecalculateFailed,
    hasFailed => !hasFailed ? null : Utils.Messages.mapErrors({ apiCommunication: 'We are having trouble processing payment. Please try again' })[0],
);

export const getSelectedOrderType = createSelector(
    state,
    onlineOrder => onlineOrder.orderType
);

export const orderTypeFieldsVisible = (config: IConfig) => createSelector(
    state,
    onlineOrder => Utils.OnlineOrders.hasOrderTypePristineDisclaimerFields(onlineOrder?.orderType, config)
);

export const isOrderTypeValid = (config: IConfig) => createSelector(
    state,
    fromOrderTypes.getOrderTypesForAllLocations,
    (onlineOrder, orderTypes) => {
        const _ = onlineOrder.orderType;

        if (_ === null && orderTypes && onlineOrder.data?.PickupLocation) return orderTypes.map(type => type.locationNo).includes(onlineOrder?.data?.PickupLocation);

        if (!_) return false;

        const isDisclaimerValid: boolean = Utils.OnlineOrders.isOrderTypeDisclaimerValid(_, config);

        if (_.Details.length === 0) {
            return isDisclaimerValid;
        }

        const hasRequired = _.Details.some(obj => obj.IsRequired === true);
        if (!hasRequired) return _.Details.reduce((flag, field) => {
            if (flag === false) return false;

            return field.hasOwnProperty('_Value');
        }, isDisclaimerValid);

        return _.Details.reduce((flag, field) => {
            if (flag === false) return false;
            if (field.IsRequired && !!field._Value === false) {
                return false;
            }

            return true;
        }, isDisclaimerValid);
    }
);

export const isOrderTypeValidForPosting = createSelector(
    state,
    onlineOrder => {
        const _ = onlineOrder.orderType;
        if (!_ || _.Details.length === 0) return true;

        const hasRequired = _.Details.some(obj => obj.IsRequired === true);

        if (!hasRequired) return true;

        return _.Details.reduce((flag, field) => {
            if (flag === false) return false;
            if (field.IsRequired) {
                return !!field._Value;
            }

            return flag;
        }, true);
    }
);

export const isSendingEmailReceipt = createSelector(
    state,
    onlineOrder => onlineOrder.sendEmailReceipt.isSending
);

export const hasSendingEmailReceiptSucceeded = createSelector(
    state,
    onlineOrder => onlineOrder.sendEmailReceipt.hasSucceeded
);

export const hasSendingEmailReceiptFailed = createSelector(
    state,
    onlineOrder => onlineOrder.sendEmailReceipt.hasFailed
);

export const hasMetMinimumOrderValueCriteria = createSelector(
    state,
    onlineOrder => {
        if (onlineOrder.data && onlineOrder.orderType) {
            return onlineOrder.data.TotalLeftToPay >= (onlineOrder.orderType.MinimumOrderValue || 0);
        }

        return null;
    }
);

export const minimumOrderTypeValue = createSelector(
    state,
    onlineOrder => onlineOrder.orderType?.MinimumOrderValue || null
);

export const isPaymentDisabledForOnlineOrderValidation = (config: IConfig) => createSelector(
    getOnlineOrderState,
    isOrderTypeValid(config),
    hasMetMinimumOrderValueCriteria,
    (orderState, isValidOrderType, orderValueValid) => orderState.updateRequest.isUpdating ||
        orderState.downloadRequest.isDownloading ||
        orderState.createRequest.isCreating ||
        orderState.recalculateRequest.isRecalculating ||
        !isValidOrderType ||
        orderValueValid === false
);


export const onlineOrderErrorsMapped = createSelector(
    state,
    hasRecalculateFailedErrorMapped,
    (onlineOrder, errors) => {
        if (errors) return errors;

        return null;
    }
);

export const errorMinimumSpendCriteria = createSelector(
    state,
    hasMetMinimumOrderValueCriteria,
    (onlineOrder, hasMetValueCriteria) => {
        if (hasMetValueCriteria === false) {
            return Utils.Messages.mapErrors({ minimumOrderValue: `You have not reached the minimum spend of $${onlineOrder.orderType.MinimumOrderValue.toFixed(2)}` });
        }

        return null;
    }
);

export const getOnlineOrderVoucherCode = createSelector(
    state,
    onlineOrder => onlineOrder.voucherRequest.code
);

export const hasActiveVouchers = createSelector(
    state,
    onlineOrder => !!onlineOrder.data?.ActivatedVouchers?.length
);

export const isVoucherError = createSelector(
    state,
    onlineOrder => onlineOrder.voucherRequest.error && onlineOrder.voucherRequest.error?.status !== 200
);

export const isVoucherLoading = createSelector(
    state,
    onlineOrder => onlineOrder.voucherRequest.isVoucherAdding
);

export const isVoucherFailed = createSelector(
    state,
    onlineOrder => onlineOrder.voucherRequest.hasFailed
);


export const voucherErrorMessage = createSelector(
    state,
    onlineOrder => [(onlineOrder.voucherRequest.error?.error?.Message || '')]
);

export const getActiveVoucher = createSelector(
    state,
    onlineOrder =>
        onlineOrder.data?.ActivatedVouchers?.length ?
            onlineOrder.data?.ActivatedVouchers[0] :
            null
);
