import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './online-menu.actions';
import { IOnlineMenu } from './online-menu.interface';

const initialState: IOnlineMenu = {
    isDownloading: false,
    hasSucceeded: false,
    hasFailed: false,
    downloadedDate: null,
    pickupDate: null,
    locationNo: null,
    data: null,
};

export const onlineMenuReducerFn = createReducer(
    initialState,
    on(actions.OnlineMenuPagesRequest, (state, action) => ({
        ...state,
        isDownloading: true,
        hasSucceeded: false,
        hasFailed: false,
        pickupDate: action.pickupDate,
        locationNo: action.locationNo,
    })),
    on(actions.OnlineMenuPagesSuccessRequest, (state, action) => ({
        ...state,
        isDownloading: false,
        hasSucceeded: true,
        hasFailed: false,
        locationNo: action.locationNo,
        data: action.payload,
        downloadedDate: new Date().getTime(),
    })),
    on(actions.OnlineMenuPageErrorRequest, (state, action) => ({
        ...state,
        isDownloading: false,
        hasSucceeded: false,
        hasFailed: true,
        locationNo: action.locationNo,
    })),
);

export function onlineMenuReducer(state: IOnlineMenu | undefined, action: Action) {
    return onlineMenuReducerFn(state, action);
}
