import { Params } from '@angular/router';
export class UrlParams {
    public static standardizeParamsCase(params: Params): { [key: string]: string; } {

        const currentParams = params;
        const orderTypeId = 'orderTypeId';
        const tableNo = 'tableNo';
        const menuId = 'menuId';
        const pageId = 'pageId';
        let newParams: { [key: string]: string; } = {};

        for (let param in currentParams) {
            const lowerParam = param.toLowerCase();
            const currentParam = currentParams[param];
            switch (lowerParam) {
                case orderTypeId.toLowerCase():
                    newParams[orderTypeId] = currentParam;
                    break;
                case tableNo.toLowerCase():
                    newParams[tableNo] = currentParam;
                    break;
                case menuId.toLowerCase():
                    newParams[menuId] = currentParam;
                    break;
                case pageId.toLowerCase():
                    newParams[pageId] = currentParam;
                    break;
                default:
                    newParams[param] = currentParam;
            }
        }

        return newParams;
    }
}
