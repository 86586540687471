import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import * as State from '@shared/state';
import * as Tokens from '@shared/core/tokens';
import * as Services from '@shared/core/services';
import * as Utils from '@shared/core/utils';

import { Observable, of, combineLatest as ObservableCombineLatest } from 'rxjs';
import { map, distinctUntilChanged, auditTime, filter, combineLatest, withLatestFrom, switchMap, take } from 'rxjs/operators';
import { ILoyaltyAppCountryAssignmentModel, IMemberChangePasswordBusinessModel, IMemberModel } from '@shared/state';

@Injectable({
    providedIn: 'root'
})
export class MembersController {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _store: Store<State.IStateShared>,
        private _modalsService: Services.ModalsService
    ) { }

    public deleteProfile(): void {
        this._store.dispatch(actions.MemberDeleteProfileRequest());
    }

    public getAccountBalance$(): Observable<State.IAccountBalance> {
        return this._store
            .pipe(
                select(selectors.getAccountBalance)
            );
    }

    public verifyLinkRewardsAccount(memberCardNo: string, email: string): void {
        this._store.dispatch(actions.MemberLinkRewardsAccountVerifyRequest({ params: { memberCardNo, email } }));
    }


    public linkRewardsAccount(password: string): void {
        this._store.dispatch(actions.MemberLinkRewardsAccountRequest({ password }));
    }

    public hideAccountBalanceAmount$(): Observable<boolean> {
        return this.getAccountBalance$()
            .pipe(
                map(balance => balance?.data?.AvailableBalance > this._config.accountCharge?.balanceDisplayThreshold ? true : false)
            );
    }

    public showAccountBalance$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.showAccountBalance(this._config))
            );
    }

    public showAccountPaymentMethod$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.showAccountPaymentMethod(this._config))
            );
    }

    public uniqueCodeErrorText$(): Observable<string> {
        return this._store
            .pipe(
                select(selectors.getMemberState),
                map(state => {
                    const unavailable: string = 'This link is no longer available';
                    const registered: string = 'It looks like you’re already registered.';

                    return state.uniqueCode.hasFailed ? unavailable : registered;
                })
            );
    }

    public uniqueCodeErrorType$(): Observable<number> {
        return this._store
            .pipe(
                select(selectors.getMemberState),
                map(state => state.uniqueCode.hasFailed ? 0 : 1)
            );
    }

    public signOut(redirect: boolean | string = '/', resetCart: boolean = false): void {
        this._store.dispatch(
            actions.MemberSignOut({ redirect, resetCart })
        );
    }

    public getCurrentMemberCardNumber$(): Observable<string> {
        return this._store
            .pipe(
                select(selectors.getMemberState),
                map(state => state.data && state.data.CardNumber || null)
            );
    }

    public updateMemberProfile(memberData: IMemberModel, modalId: number = -100): void {
        this._store.dispatch(actions.MemberProfileUpdateInit({
            userModel: { ...memberData },
            modalId,
        }));
    }


    public skipBirthdayRewardsForNow(modal: boolean): void {
        Utils.Storage.set(OLO.Enums.USER_STORAGE.BIRTHDAY_REWARDS, 'skip');
        if (modal) {
            this._modalsService.closeAllWithAnimation();
        }
    }

    public setSignUpStep(): void {
        this._store.dispatch(actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.REGISTER }));
    }

    public setLinkRewardsAccountStep(): void {
        this._store.dispatch(actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.ACCOUNT_LINKING_REWARDS_SIGN_UP }));
    }

    public setNewPasswordStep(): void {
        this._store.dispatch(actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.ACCOUNT_LINKING_REWARDS_PASSWORD }));
    }

    public openMemberSignUpForm(): void {
        this.setSignUpStep();

        this._modalsService.show({
            type: 'auth',
        });
    }


    public openMemberSignInForm(): void {
        this._store.dispatch(actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.LOGIN }));
        this._modalsService.show({
            type: 'auth',
        });
    }

    public skipMemberCardSignUp(): void {
        this._store.dispatch(actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.LOGIN }));
    }

    public goToLoginAuthStep(resetValidation: boolean = false): void {
        this._store
            .pipe(
                select(selectors.getAllModals),
                take(1),
            ).subscribe(modals => {
                this._store.dispatch(actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.LOGIN }));

                if (resetValidation) {
                    this.resetValidationState();
                }

                if (modals.find(obj => obj.type === 'auth')) {
                    return;
                }

                this._modalsService.show({
                    type: 'auth'
                });
            });
    }

    public getMemberEmailConfirmState$(): Observable<State.IMemberConfirmEmailAddress> {
        return this._store
            .pipe(
                select(selectors.getMemberEmailConfirmState)
            );
    }

    public isAuthorized$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isMemberAuthorizedJWT)
            );
    }

    public getSignInLabel$(defaultLabel: string = 'Sign in'): Observable<string> {
        return this.isAuthorized$()
            .pipe(
                switchMap(isAuthorized => {
                    if (!isAuthorized) return of(defaultLabel);

                    return this._store
                        .pipe(
                            select(selectors.getCurrentMember),
                            map(member => {
                                if (member) return member.MemberName;

                                return 'You';
                            })
                        );
                })
            );
    }

    public isGuestMember$(): Observable<boolean> {
        return this.isAuthorized$()
            .pipe(
                combineLatest(
                    this._store
                        .pipe(
                            select(selectors.isGuestModeEnabled)
                        )
                ),
                map(([isAuthorized, isGuestModeEnabled]) => isAuthorized === false && isGuestModeEnabled === true)
            );
    }

    public requestGuestMemberDataBasedOnOrder(orderId: number): void {
        this._store
            .pipe(
                select(selectors.getHistoryOrdersState),
                auditTime(100),
                filter(state => state.isDownloading === false),
                withLatestFrom(
                    this._store
                        .pipe(
                            select(selectors.isMemberAuthorizedJWT)
                        )
                ),
                take(1),
            ).subscribe(([state, isAuthorized]) => {
                if (isAuthorized) return;
                const order = state.orders?.find(obj => obj.OrderId === orderId && obj.data !== null);

                const MemberEmail = order?.data?.MemberEmail || state.orders[0].data.MemberEmail;
                if (MemberEmail) {
                    this._store.dispatch(actions.MemberGuestDataRequest({ login: MemberEmail, loginType: OLO.Enums.LOGIN_TYPE.EMAIL_BASED_LOGIN }));
                }
            });
    }

    public getGuestValidationDetails$(): Observable<State.IMemberGuestValidationDetails> {
        return this._store
            .pipe(
                select(selectors.getGuestValidationDetails)
            );
    }

    public isMemberLoading$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isMemberLoading)
            );
    }

    public getAccountLogin$(): Observable<string> {
        return this._store
            .pipe(
                select(selectors.getMemberState),
                map(state => state.authorizationType === OLO.Enums.LOGIN_TYPE.MOBILE_PHONE_BASED_LOGIN && state.accountLogin || null),
            );
    }

    public getAccountLoginCellPhoneNumber$(): Observable<string> {
        return this._store
            .pipe(
                select(selectors.getMemberState),
                map(state => {
                    if (state.authorizationType !== OLO.Enums.LOGIN_TYPE.MOBILE_PHONE_BASED_LOGIN) {
                        return state.data && state.data.MobilePhone || null;
                    }

                    return state.accountLogin;
                }),
            );
    }

    public getObfuscatedMemberPhoneNo$(): Observable<string> {
        return this._store
            .pipe(
                select(selectors.getObfuscatedMemberPhoneNo)
            );
    }

    public getObfuscatedEmail$(): Observable<string> {
        return this._store
            .pipe(
                select(selectors.getObfuscatedMemberEmail)
            );
    }

    public getObfuscatedForgotEmail$(): Observable<string> {
        return this._store
            .pipe(
                select(selectors.getObfuscatedForgotMemberEmail)
            );
    }

    public isMakingRequests$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isMemberLoading),
                map(isLoading => isLoading)
            );
    }

    public isMakingRequestsAsStatus$(): Observable<OLO.Components.LOADING_STATUS> {
        return this.isMakingRequests$()
            .pipe(
                map(isLoading => (isLoading ? 'loading' : null) as OLO.Components.LOADING_STATUS)
            );
    }

    public getAuthenticationStep$(): Observable<OLO.Enums.AUTH_STEP> {
        return this.isMakingRequests$()
            .pipe(
                filter(isMakingRequests => isMakingRequests === false),
                switchMap(() => this._store
                    .pipe(
                        select(selectors.getAuthorizationStep),
                    )
                ),
            );
    }

    public canOmmitEmailVerificationStep$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.canOmmitEmailVerification(this._config.unverifiedEmailQuickLogin)),
                filter(canOmmit => canOmmit !== null),
            );
    }

    public isEmailValidated$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isEmailValidated)
            );
    }

    public hasGuestSignedUp$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.hasGuestSignedUp)
            );
    }

    public isPartialMemberSet$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.getGuestData),
                combineLatest(
                    this._store
                        .pipe(
                            select(selectors.isGuestModeEnabled)
                        )
                ),
                map(([guestData, guestMode]) => guestData !== null && guestMode === true)
            );
    }

    public getMemberDetails$(includeUniqueCode: boolean = false): Observable<IMemberModel> {
        return this._store
            .pipe(
                select(selectors.getCurrentMember),
                withLatestFrom(
                    this._store
                        .pipe(
                            select(selectors.getMemberState)
                        )
                ),
                map(([memberDetails, state]) => {
                    if (includeUniqueCode === true && state.uniqueCode.hasSucceeded && state.uniqueCode.data) return state.uniqueCode.data;

                    return memberDetails;
                })
            );
    }

    public getPartialMemberDetails$(): Observable<APICommon.IOnlineOrderPartialMember> {
        return this._store
            .pipe(
                select(selectors.getGuestData)
            );
    }

    public memberSignUpFormModel$(): Observable<OLO.Forms.ISingUp> {
        return this._store
            .pipe(
                select(selectors.getMemberState),
                map(state => {
                    if (!state.data || state.isDownloading || state.hasFailed) return null;

                    return {
                        name: state.data.MemberName,
                        email: state.data.Email,
                        cellNumber: state.data.MobilePhone,
                    };
                })
            );
    }

    public canNavBackAuthProcess$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.getMemberState),
                map(state => {
                    const step: OLO.Enums.AUTH_STEP = state.authorizationStep;

                    switch (true) {
                        case step === OLO.Enums.AUTH_STEP.REGISTER_CARD:
                        case step === OLO.Enums.AUTH_STEP.ON_LOGIN_DATA_VALIDATION:
                        case step === OLO.Enums.AUTH_STEP.LOGIN:
                        case step === OLO.Enums.AUTH_STEP.FORGOT_PASSWORD_SENT:
                        case step === OLO.Enums.AUTH_STEP.REGISTER_SUCCESS:
                        case step === OLO.Enums.AUTH_STEP.PARTIAL_MEMBER_PASSWORD:
                        case step === OLO.Enums.AUTH_STEP.LOGIN_SUCCESS:
                        case step === OLO.Enums.AUTH_STEP.UPDATE_SUCCESS:
                        case step === OLO.Enums.AUTH_STEP.UNIQUE_CODE_ERROR:
                        case step === OLO.Enums.AUTH_STEP.ACCOUNT_LINKING_REWARDS_PASSWORD:
                        case step === null:
                            return false;

                        default:
                            return true;
                    }
                })
            );
    }

    public showNavBackAuthProcess$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.getMemberState),
                map(state => {
                    const step: OLO.Enums.AUTH_STEP = state.authorizationStep;
                    switch (true) {
                        case step === OLO.Enums.AUTH_STEP.BIRTHDAY_REWARDS:
                        case step === OLO.Enums.AUTH_STEP.FORGOT_PASSWORD_SENT:
                        case step === OLO.Enums.AUTH_STEP.REGISTER_SUCCESS:
                        case step === OLO.Enums.AUTH_STEP.LOGIN_SUCCESS:
                        case step === OLO.Enums.AUTH_STEP.UPDATE_SUCCESS:
                        case step === OLO.Enums.AUTH_STEP.BEFORE_UPDATE_VERIFY_PHONE:
                        case step === OLO.Enums.AUTH_STEP.UNIQUE_CODE_ERROR:
                        case step === null:
                            return false;

                        default:
                            return true;
                    }
                })
            );
    }

    public getMemberFirstName$(): Observable<string> {
        return this._store
            .pipe(
                select(selectors.getMemberState),
                filter(state => state.data !== null),
                map(state => state.data.MemberName)
            );
    }

    public getMemberFullName$(): Observable<string> {
        return this._store
            .pipe(
                select(selectors.getMemberFullName),
                filter(name => name !== null),
            );
    }

    public showMemberCardNumber$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.getMemberState),
                filter(state => state.data !== null),
                map(state => this._config.appMode !== IAppMode.ORDERING_ONLY && state.data.CardNumber !== null),
            );
    }

    public memberCardNumber$(): Observable<string> {
        return this._store
            .pipe(
                select(selectors.getMemberState),
                filter(state => state.data !== null),
                map(state => state.data.CardNumber),
            );
    }

    public signUpInitStatus$(restoreStatusTimeout: number = 2000): Observable<OLO.Components.LOADING_STATUS> {
        return this.isMemberLoading$()
            .pipe(
                map(isLoading => isLoading ? 'loading' : null)
            );
    }
    public partialMemberSignUpStatus$(restoreStatusTimeout: number = 2000): Observable<OLO.Components.LOADING_STATUS> {
        return this.loadingStatusAutoChange$('signUpPartialMember.isSigningUp', 'signUpPartialMember.hasSucceeded', 'signUpPartialMember.hasFailed', restoreStatusTimeout);
    }

    public memberSignInStatus$(restoreStatusTimeout: number = 2000): Observable<OLO.Components.LOADING_STATUS> {
        return this.loadingStatusAutoChange$('signIn.isSigningIn', 'signIn.hasSucceeded', 'signIn.hasFailed', restoreStatusTimeout);
    }

    public memberPasswordChangeStatus$(restoreStatusTimeout: number = 2000): Observable<OLO.Components.LOADING_STATUS> {
        return this.loadingStatusAutoChange$('changePassword.isChanging', 'changePassword.hasSucceeded', 'changePassword.hasFailed', restoreStatusTimeout);
    }

    public memberLinkRewardsAccountVerifyStatus$(restoreStatusTimeout: number = 2000): Observable<OLO.Components.LOADING_STATUS> {
        return this.loadingStatusAutoChange$('verifyLinkRewardsAccount.isVerifying',
            'verifyLinkRewardsAccount.hasSucceeded',
            'verifyLinkRewardsAccount.hasFailed',
            restoreStatusTimeout);
    }

    public memberLinkRewardsAccountStatus$(restoreStatusTimeout: number = 2000): Observable<OLO.Components.LOADING_STATUS> {
        return this.loadingStatusAutoChange$('linkRewardsAccount.isLinking', 'linkRewardsAccount.hasSucceeded', 'linkRewardsAccount.hasFailed', restoreStatusTimeout);
    }

    public verifyMobilePhoneStatus$(restoreStatusTimeout: number = 2000): Observable<OLO.Components.LOADING_STATUS> {
        return this.loadingStatusAutoChange$('verifyPhone.isVerifying', 'verifyPhone.hasSucceeded', 'verifyPhone.hasFailed', restoreStatusTimeout);
    }

    public tokenMobilePhoneStatus$(restoreStatusTimeout: number = 2000): Observable<OLO.Components.LOADING_STATUS> {
        return this.loadingStatusAutoChange$('tokenForPhone.isSending', 'tokenForPhone.hasSucceeded', 'tokenForPhone.hasFailed', restoreStatusTimeout);
    }

    public mobilePhoneStatus$(restoreStatusTimeout: number = 2000): Observable<OLO.Components.LOADING_STATUS> {
        return ObservableCombineLatest(
            this.verifyMobilePhoneStatus$(restoreStatusTimeout),
            this.tokenMobilePhoneStatus$(restoreStatusTimeout)
        ).pipe(
            map(([status1, status2]) => {
                if (status1 === 'error' || status2 === 'error') {
                    return 'error';
                }

                if (status1 === 'loading' || status2 === 'loading') {
                    return 'loading';
                }

                if (status1 === 'success' || status2 === 'success') {
                    return 'success';
                }

                return null;
            })
        );
    }

    public deleteAccountStatus$(restoreStatusTimeout: number = 2000): Observable<OLO.Components.LOADING_STATUS> {
        return this.loadingStatusAutoChange$('deleteProfile.isDeleting', 'deleteProfile.hasSucceeded', 'deleteProfile.hasFailed', restoreStatusTimeout);
    }

    public validateEmailStatus$(restoreStatusTimeout: number = 2000): Observable<OLO.Components.LOADING_STATUS> {
        return this.loadingStatusAutoChange$('verifyEmail.isVerifying', 'verifyEmail.hasSucceeded', 'verifyEmail.hasFailed', restoreStatusTimeout);
    }

    public loadingStatusAutoChange$(loadingProperty: string, successProperty: string, failedProperty: string, restoreStatusTimeout: number = 2000):
    Observable<OLO.Components.LOADING_STATUS> {
        return this._store
            .pipe(
                select(selectors.getMemberState),
                auditTime(20),
                switchMap(state => Observable.create(observer => {
                    const loadingState = Utils.Objects.extractSubValue<boolean>(state, loadingProperty);
                    const successState = Utils.Objects.extractSubValue<boolean>(state, successProperty);
                    const failedState = Utils.Objects.extractSubValue<boolean>(state, failedProperty);

                    const propsInvalid: boolean = loadingState === undefined || successState === undefined || failedState === undefined;
                    if (propsInvalid) {
                        console.warn(`One of following properties are invalid. loadingState ("${loadingProperty}"):`,
                            loadingState, ` OR successState ("${successProperty}"):`, successState, ` OR failedState ("${failedProperty}"):`, failedState);

                        observer.next(null);
                        observer.complete();

                        return;
                    }

                    let timeout: number;

                    const neutral = () => {
                        observer.next(null);
                        observer.complete();
                    };

                    const loading = () => {
                        observer.next('loading');
                        observer.complete();
                    };

                    const success = () => {
                        observer.next('success');
                        timeout = window.setTimeout(() => {
                            observer.next(null);
                            observer.complete();
                        }, restoreStatusTimeout);
                    };

                    const error = () => {
                        observer.next('error');
                        timeout = window.setTimeout(() => {
                            observer.next(null);
                            observer.complete();
                        }, restoreStatusTimeout);
                    };

                    const singlePropertyToDetermineSuccessError: boolean = failedProperty === successProperty;
                    if (singlePropertyToDetermineSuccessError) {
                        if (loadingState) {
                            loading();
                        } else {
                            if (!successState && !failedState) {
                                error();
                            } else {
                                success();
                            }
                        }
                    } else {
                        if (loadingState) {
                            loading();
                        } else if (failedState) {
                            error();
                        } else if (successState) {
                            success();
                        } else {
                            neutral();
                        }
                    }

                    return () => window.clearTimeout(timeout);

                }) as Observable<OLO.Components.LOADING_STATUS>),
                distinctUntilChanged(),
            );
    }

    public linkAccountRewardsError$(): Observable<{ [key: string]: string; }> {
        return this._store
            .pipe(
                select(selectors.getMemberState),
                map(state => state?.verifyLinkRewardsAccount?.hasFailed === true ? { email: 'Incorrect email address' } : null)
            );
    }

    public signUpPartialMemberError$(): Observable<{ [key: string]: string; }> {
        return this._store
            .pipe(
                select(selectors.partialMemberSignUpFailed),
                map(hasFailed => hasFailed && { partialMemberSignUpError: 'Something went wrong' } || null)
            );
    }

    public signInError$(): Observable<{ [key: string]: string; }> {
        return this._store
            .pipe(
                select(selectors.getMemberState),
                map(({ signIn }) => {
                    if (signIn.hasFailed) return { incorrectPassword: 'Incorrect password' };

                    return null;
                })
            );
    }

    public cellVerificationError$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isMemberPhoneVeryficationCodeInvalid)
            );
    }

    public passwordChangeError$(): Observable<{ [key: string]: string; }> {
        return this._store
            .pipe(
                select(selectors.passwordChangeError)
            );
    }

    public getMemberForgottenPasswordState$(): Observable<State.IMemberForgottenPasswordReset> {
        return this._store
            .pipe(
                select(selectors.getMemberResetForgottenPasswordState)
            );
    }

    public emailConfirmationIsCheckingTokens$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isCheckingTokensForEmailConfirmation)
            );
    }

    public emailConfirmationGUIDTokenIsValid$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isEmailGUIDTokenValid)
            );
    }

    public emailConfirmationHasSucceeded$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isEmailConfirmationSuccess)
            );
    }

    public isSendingPhoneToken$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.getMemberState),
                map(state => state.tokenForPhone.isSending),
            );
    }

    public isSendingEmailToken$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.getMemberState),
                map(state => state.tokenForEmail.isSending),
            );
    }

    public partialMemberInitFlowReset(): void {
        this._store.dispatch(actions.MemberPartialSignUpResetState());
        this._store.dispatch(actions.MemberPasswordSetResetState());
    }

    public confirmMemberEmailAddress(guidToken: string): void {
        this._store.dispatch(actions.MemberConfirmEmailRequest({ token: guidToken }));
    }

    public confirmGUIDTokenForEmailConfirmation(guidToken: string): void {
        this._store.dispatch(actions.MemberConfirmEmailTokenRequest({ token: guidToken }));
    }

    public resetPasswordInit(guidToken: string, NewPassword: string): void {
        this._store.dispatch(actions.MemberForgottenPasswordResetRequest({
            model: {
                Token: guidToken,
                NewPassword
            }
        }));
    }

    public resetPasswordGUIDTokenValidate(guidToken: string): void {
        this._store.dispatch(actions.MemberValidatePasswordResetTokenRequest({ token: guidToken }));
    }

    public resetPasswordValidationTokenState(): void {
        this._store.dispatch(actions.MemberValidatePasswordResetTokenReset());
    }

    public updatePassword(values: IMemberChangePasswordBusinessModel): void {
        this._store
            .pipe(
                select(selectors.getMemberState),
                take(1)
            ).subscribe(state => {
                this._store.dispatch(
                    actions.MemberPasswordChangeRequest({ ...values, MemberId: state.data.MemberId }),
                );
            });
    }

    public checkMemberToCompleteAccountSetup(orderId: number): void {
        this._store.dispatch(actions.MemberCompleteAccountSetupGuestCheck({ orderId }));
    }

    public setAuthStep(step: OLO.Enums.AUTH_STEP = OLO.Enums.AUTH_STEP.LOGIN): void {
        this._store.dispatch(actions.MemberAuthorizationSetStep({ step }));
    }

    public forgotPasswordResetInit(email: string = null): void {
        if (email) {
            return this._store.dispatch(actions.MemberForgotPasswordDataRequest({ email }));
        }

        this._store
            .pipe(
                select(selectors.getMemberState),
                take(1),
            ).subscribe(state => {
                if (state.forgotPassword.email) {
                    this._store.dispatch(actions.MemberForgotPasswordDataRequest({ email: state.forgotPassword.email }));
                }
            });
    }

    public signIn(login: string, password: string): void {
        this._store.dispatch(actions.MemberSignInDataRequest({ login, password }));
    }

    public resetValidationState(): void {
        this._store.dispatch(actions.MemberGuestDataReset());
        this._store.dispatch(actions.MemberUnsetAddData());
        this._store.dispatch(actions.MemberUnsetAccountCredentials());
        this._store.dispatch(actions.MemberUnsetUpdateAndValidation());
    }

    public resetMemberDataState(): void {
        this._store.dispatch(actions.MemberDataReset());
    }

    public resetMemberDataFields(): void {
        this._store.dispatch(actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.LOGIN }));

        this.resetValidationState();

        this._store
            .pipe(
                select(selectors.isMemberAuthorizedJWT),
                combineLatest(
                    this._store
                        .pipe(
                            select(selectors.isMemberLoading),
                            filter(isLoading => isLoading === false),
                            take(1)
                        )
                ),
                take(1)
            ).subscribe(([isAuthorized, isLoading]) => {
                if (isAuthorized) {
                    this._store.dispatch(actions.MemberGuestModeSet({ flag: false }));
                } else {
                    this._store.dispatch(actions.MemberGuestModeSet({ flag: true }));
                }
            });
    }


    public cleanupAfterOrderComplete(): void {
        this._store
            .pipe(
                select(selectors.isMemberAuthorizedJWT),
                take(1)
            ).subscribe(isAuthorized => {
                if (isAuthorized) return;

                this.resetMemberDataFields();
            });
    }

    public initSignIn(accountLogin: string, authorizationType: OLO.Enums.LOGIN_TYPE = OLO.Enums.LOGIN_TYPE.MOBILE_PHONE_BASED_LOGIN): void {
        this._store.dispatch(actions.MemberAuthorizationInit({ authorizationType, accountLogin }));
        this._store.dispatch(actions.MemberGuestModeSet({ flag: false }));
    }

    public continueEmailVerification(): void {
        this._store
            .pipe(
                select(selectors.getMemberState),
                filter(memberState => memberState !== null && memberState.data !== null),
                take(1)
            ).subscribe(memberState => {
                // this._store.dispatch(actions.MemberDataRequest());
                this._store.dispatch(actions.MemberVerifyEmailRequest());
            });
    }

    public restoreEmailVerificationFlags(onDestory: boolean = true): void {
        if (!onDestory) {
            this._store.dispatch(actions.MemberVerifyEmaiResetFlags());
        }
    }

    public resendVerificationEmail(resetVerifyFlags: boolean = true): void {
        if (resetVerifyFlags) {
            this.restoreEmailVerificationFlags(false);
        }
        this._store
            .pipe(
                select(selectors.getMemberState),
                filter(member => member !== null && member.data !== null),
                take(1)
            ).subscribe(({ data }) => {
                this._store.dispatch(actions.MemberVerifyEmailRestoreFlags());
                this._store.dispatch(actions.MemberSendEmailVeryficationRequest({ email: data.Email }));
            });
    }

    public verifyTemporaryVerificationCode(token: string): void {
        this._store
            .pipe(
                select(selectors.getMemberPhoneNo),
                combineLatest(
                    this._store.select(selectors.getMobilePhoneCountryId),
                    this._store.select(selectors.getCountryAssignments),
                    this._store.select(selectors.getMemberState)
                ),
                take(1),
            ).subscribe(([phoneNo, countryId, countryAssignments, memberState]) => {
                const country = countryAssignments.find(obj => obj.Id === (typeof countryId === 'number' ? countryId : memberState.addData.MobilePhoneCountryId));
                const countryPrefix = +country.PhonePrefix;
                this._store.dispatch(actions.MemberVerifyPhoneDataRequest({ phoneNo, token, countryPrefix }));
            });
    }

    public async completeMemberAccountSetup(memberId: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this._store
                .pipe(
                    select(selectors.getHistoryOrdersState),
                    combineLatest(
                        this._store
                            .pipe(
                                select(selectors.getLoyaltyAppSettings),
                            )
                    ),
                    take(1),
                ).subscribe(([orders, { data }]) => {
                    const order = orders.orders[0].data;
                    const countries: ILoyaltyAppCountryAssignmentModel[] = (data?.CountryAssignments ? data.CountryAssignments : []);

                    const member: IMemberModel = {
                        PartialMemberId: order.MemberId,
                        MemberName: order.MemberFirstName,
                        MemberSurname: order.MemberLastName,
                        Email: order.MemberEmail,
                        MobilePhonePrefix: order.MemberMobilePhoneCountryID ? countries.filter(c => c.Id === order.MemberMobilePhoneCountryID)[0]?.PhonePrefix : '+1',
                        MobilePhone: order.MemberMobilePhone,
                        MobilePhoneCountryId: order.MemberMobilePhoneCountryID ? order.MemberMobilePhoneCountryID : 222,
                        MobileNumber: order.MemberMobilePhone
                    };

                    this._store.dispatch(actions.MemberDataSuccessRequest({ memberId: order.MemberId, payload: member }));

                    this._store
                        .pipe(
                            select(selectors.getMemberState),
                            take(1)
                        ).subscribe(() => {
                            setTimeout(() => {
                                this._store.dispatch(actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.PARTIAL_MEMBER_PASSWORD }));
                                resolve(true);
                            }, 100);
                        });
                });
        });
    }

    public signUpPartialMember(memberId: number, memberData: IMemberModel): void {
        this._store.dispatch(actions.MemberPartialSignUpRequest({ memberId, memberData }));
    }

    public signUpInit(memberData: IMemberModel): void {
        this._store.dispatch(actions.MemberSignUpProcessInit({ memberData }));
    }

    public createGuestPartialMember(guestData: APICommon.IOnlineOrderPartialMember): void {
        this._store.dispatch(actions.MemberGuestDataRevalidateAndSet({ guestData }));
    }
}
