import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { OnlineMenuProductsImagesEffects } from './online-menu-products-images.effects';
import { onlineMenuProductsImagesReducer } from './online-menu-products-images.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature('onlineMenuProductsImages', onlineMenuProductsImagesReducer),
        EffectsModule.forFeature([
            OnlineMenuProductsImagesEffects
        ]),
    ],
})
export class OnlineMenuProductsImagesStateModule { }
