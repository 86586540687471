import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';
import * as State from '@shared/state/interface';

import * as actions from '@shared/state/actions';

import { Observable } from 'rxjs';
import {
    IExecuteAccountChargeResponse,
    IExecuteOnlineOrderAccountChargeModel,
    IExecutePaymentModel,
    IExecutePaymentResponse,
    IGetTransactionResponse
} from '@shared/state/interface';
import { PaymentsMapper } from '@shared/core/mappers/payments.shared.mapper';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PaymentsService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public httpClient: HttpClient,
        public store: Store<State.IStateShared>,
    ) {
    }

    public payWithAccountCharge(orderId: number, requestModel: IExecuteOnlineOrderAccountChargeModel): Observable<IExecuteAccountChargeResponse> {
        const mapedRequestModel: APIv3.ExecuteOnlineOrderAccountChargeModel = PaymentsMapper.mapPayWithAccountChargePOSTRequest(requestModel);

        return this.httpClient
            .post<APIv3.ExecuteAccountChargeResponse>(
            `${Utils.HTTP.switchApi(this.config.api.base)}/OnlineOrders/${orderId}/accountCharge`, mapedRequestModel)
            .pipe(
                map((response: APIv3.ExecuteAccountChargeResponse) => PaymentsMapper.mapPayWithAccountChargePOSTResponse(response))
            );
    }

    public pay(orderId: number, requestModel: IExecutePaymentModel): Observable<IExecutePaymentResponse> {
        const mapedRequestModel: APIv3.ExecutePaymentModel = PaymentsMapper.mapPayPOSTRequest(requestModel);

        return this.httpClient
            .post<APIv3.ExecutePaymentResponse>(`${Utils.HTTP.switchApi(this.config.api.base)}/OnlineOrders/${orderId}/pay`, mapedRequestModel)
            .pipe(
                map((response: APIv3.ExecutePaymentResponse) => PaymentsMapper.mapPayPOSTResponse(response))
            );
    }

    public getPaymentStatus(transactionId: string): Observable<IGetTransactionResponse> {
        return this.httpClient
            .get<APIv3.GetTransactionResponse>(`${Utils.HTTP.switchApi(this.config.api.base)}/Payments/${transactionId}`)
            .pipe(
                map((response: APIv3.GetTransactionResponse) => PaymentsMapper.maPaymentStatusGETResponse(response))
            );
    }

    public resetPaymentFlow(): void {
        this.store.dispatch(actions.PaymentReset());
    }

    public cleanUp(): void {
        /* When exiting checkout page, make sure online order, payment and credit card data is reset */
        this.store.dispatch(actions.OnlineOrderStateReset());
        this.store.dispatch(actions.CreditCardTokenDataReset());
        this.store.dispatch(actions.PaymentReset());
    }

}
