import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, ICart, ICartSimpleItem, ICartMenuFlow } from '../interface';
import { Pricing } from '@shared/core/utils';

const state = createFeatureSelector<IStateShared, ICart>('cart');

export const getCart = createSelector(
    state,
    cart => cart
);

export const getCartCollectionType = createSelector(
    state,
    cart => cart.collectionTypeId
);

export const isCartCollectionTypeDineIn = (config: IConfig) => createSelector(
    state,
    cart => {
        if (!cart?.collectionTypeId) return false;

        return cart?.collectionTypeId === config?.collectionTypes?.dineIn?.dineInBuzzer?.orderTypeId
            || cart?.collectionTypeId === config?.collectionTypes?.dineIn?.dineInTable?.orderTypeId;
    }
);

export const getCartPickupTime = createSelector(
    state,
    cart => cart.pickupTime
);

export const getCartPickupTimeName = createSelector(
    state,
    cart => {
        const p = cart.pickupTime;
        if (!p) return null;

        if (!p.IsAsap) return p.Name;

        return p.MinutesFromNow ? `ASAP - ${p.MinutesFromNow} mins` : 'ASAP';
    }
);

export const getCartLocationNo = createSelector(
    state,
    cart => cart.locationNo
);

export const getCartTotalQuantity = createSelector(
    state,
    cart => {
        let total: number = 0;

        /* Regular loop is ~10x faster than 'reduce' method */
        for (let i = 0, j = cart.itemsSimple.length; i < j; i++) {
            total += cart.itemsSimple[i].Quantity;
        }

        for (let i = 0, j = cart.itemsMenuFlow.length; i < j; i++) {
            total += cart.itemsMenuFlow[i].Quantity;
        }

        return total;
    }
);

export const getCartTotalValue = createSelector(
    state,
    cart => {
        let totalValue: number = 0;

        for (let i = 0, j = cart.itemsSimple.length; i < j; i++) {
            totalValue += cart.itemsSimple[i].Quantity * cart.itemsSimple[i].UnitPrice;
        }

        for (let i = 0, j = cart.itemsMenuFlow.length; i < j; i++) {
            const extraPrice = Pricing.calculateExtraPriceForIngredients(cart.itemsMenuFlow[i]);

            totalValue += (cart.itemsMenuFlow[i].UnitTotalValue + extraPrice) * cart.itemsMenuFlow[i].Quantity;
        }

        return totalValue;
    }
);

/* For simple items / products */
export const getCartSimpleItems = createSelector(
    state,
    cart => cart.itemsSimple
);


export const getCartSimpleItemById = (id: number) => createSelector(
    state,
    cart => cart.itemsSimple.find(item => item._Id === id)
);

export const getCartSimpleItemByProductId = (productId: number) => createSelector(
    state,
    cart => cart.itemsSimple.find(item => item.ProductId === productId)
);

export const getCartSimpleItemQuantity = (Plu: number) => createSelector(
    state,
    (cart) => cart.itemsSimple.reduce((acc, curr) => {
        if (curr.Plu === Plu) {
            return acc += curr.Quantity;
        }

        return acc;
    }, 0)
);

export const isSimpleItemInCart = (productId: number) => createSelector(
    getCartSimpleItemByProductId(productId),
    product => !!product
);

/* For menu flows */
export const getCartMenuFlows = createSelector(
    state,
    cart => cart.itemsMenuFlow
);

export const getCartMenuFlowById = (tempId: number) => createSelector(
    state,
    cart => cart.itemsMenuFlow.find(item => item._Id === tempId)
);

export const getCartMenuFlowQuantity = (tempId: number) => createSelector(
    getCartMenuFlowById(tempId),
    menuFlow => menuFlow ? menuFlow.Quantity : 0
);

export const getCartMenuFlowQuantityByMenuFlowId = (menuFlowId: number) => createSelector(
    state,
    cart => cart.itemsMenuFlow.reduce((acc, menuFlow) => {
        if (menuFlow.MenuFlowId === menuFlowId) {
            return acc += menuFlow.Quantity;
        }

        return acc;
    }, 0)
);

// export const isCartPendingClearSet = createSelector(
//     state,
//     cart => cart.pendingCartClear
// );

export const isCartEmpty = createSelector(
    state,
    cart => {
        const isMenuFlowEmpty: boolean = cart.itemsMenuFlow === null || cart.itemsMenuFlow.length === 0;
        const isSimpleEmpty: boolean = cart.itemsSimple === null || cart.itemsSimple.length === 0;

        return isMenuFlowEmpty && isSimpleEmpty;
    }
);
/* Array<State.ICartMenuFlow & State.ICartSimpleItem>[] */

export const getCartAllItems = createSelector(
    getCartSimpleItems,
    getCartMenuFlows,
    (products, menuFlows) => {
        let arr: Array<ICartMenuFlow | ICartSimpleItem> = [];

        if (products && products.length) {
            arr = [...products];
        }

        if (menuFlows && menuFlows.length) {
            arr = [
                ...arr,
                ...menuFlows
            ];
        }

        return arr.sort((a, b) => {
            switch (true) {
                case a._Id > b._Id:
                    return 1;

                case a._Id < b._Id:
                    return -1;

                default:
                    return 0;
            }
        });
    }
);

export const getCartOnlineMenuItemById = (id: number) => createSelector(
    state,
    cart => {
        if (!cart.onlineMenu) return null;

        let product: APICommon.IOnlineMenuProductResponseModel = null;

        for (let i = 0, j = cart.onlineMenu.Pages.length; i < j; i++) {
            if (product) break;

            const page = cart.onlineMenu.Pages[i];

            for (let k = 0, m = page.Products.length; k < m; k++) {
                if (page.Products[k].ProductId === id || page.Products[k].MenuFlowId === id) {
                    product = page.Products[k];
                    break;
                }
            }
        }

        return product;
    }
);
